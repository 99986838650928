import { useEffect,useContext, useState } from 'react'
import ApiServices from '../../utils/ApiServices'
import moment from 'moment'
import {
  CardImage,
  PencilSquare,
  HeartFill,
  Chat,
  CursorFill,
  Heart,
  Trash
} from 'react-bootstrap-icons';
import Switch from "../ToggleButton";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";
import { Modal, Dropdown,Button } from 'react-bootstrap';
import { getUserToken } from "../../utils/helper";
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';

const Feeds = (props) => {
  let { userInfo } = props;
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const { logOut } = useContext(UserContext)
  const [getFeeds, setGetFeeds] = useState()
  const [feeds, setFeeds] = useState(false)
  const [showRModal, setShowrmodal] = useState(false)
  const [showPModal, setShowpmodal] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [uploadFile, setUploadFile] = useState('')
  const [postDesc, setPostDesc] = useState('')
  const [reasonList, setReasonList] = useState([]);
  const [state, setState] = useState({ reasonValue: '', reasonId: '' });
  const [postinfo, setPostinfo] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [perPage, setPerpage] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [showdt, setShowDt] = useState(false)
  const[dtkey,setDtkey]=useState()
  const[loading,setLoading]=useState(false)

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }


  const fetchData = async (event) => {
    let query = feeds ? 'yes' : 'no';
    const res = await ApiServices.get(`astrologer/post/list/${pageOffset + 1}/${perPage}/${query}`, {
      Authorization: getUserToken()
    })
    if (res.status == 200) {
      setGetFeeds(res?.data?.list);
      setPageCount(Math.ceil(res?.data?.total_count / perPage));
    } else {
      // console.log('res', res.response.data.status)
      if(res.response.data.status==4){
        logOut()
      }
    }
  }

  const handleModalShow = async (data) => {
    setPostinfo(data);
    setState((prevState) => ({ ...prevState, reasonValue: '', reasonId:'' }))
    const res = await ApiServices.get(`astrologer/report_reason`, {
      Authorization: getUserToken()
    })
    if (res.status == 200 && res.data.status == '1') {
      setReasonList(res?.data?.list);
      setShowrmodal(true)
    } else {
      console.log('res', res)
    }
  }

  useEffect(() => {
    setPostDesc('');
    setUploadFile('');
    fetchData();
    setShowrmodal(false);
    setPostinfo('');
  }, [refetch])

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    setRefetch(!refetch)
    window.scrollTo(0,0)
  };


  const renderPostImages = data => {
      return (
        <>
          <img
            src={data?.astrologerId?.profileImage || data?.userId?.profileImage}
            className='img-fluid user_img'
          />
          <div className='ms-2'>
            <h4>{data?.astrologerId?.name || data?.userId?.name}</h4>
            <p>{moment(data?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
          </div>
        </>
      )
  }

  const handleWall = () => {
      setPageOffset(0)
      setFeeds(!feeds);
      setRefetch(!refetch)
  }

  const handleLikeCount = async (row) => {
    let payload = {
      "postId": row?._id,
      "likeStatus": !row.likeStatus
    }
    let res = await ApiServices.post("astrologer/post/like_dislike", payload, {
      Authorization: getUserToken()
    })
    if (res.status == 200)
      setRefetch(!refetch)
    else {
      console.log('res', res)
    }
  }

  const navigateToFeeds = (id) => {
    navigate(`/feed/${id}`, { replace: false });
  };

  const handleCreateNewPost = async () => {
    if (postDesc) {
      const payload = new FormData();
      payload.append('postType', 'public');
      payload.append('description', postDesc);
      payload.append('addtionalDetailCheck', false);
      payload.append('additionalDetails', userInfo);
      payload.append('mediaFile', uploadFile);

      let res = await ApiServices.post("/astrologer/post/create", payload, {
        Authorization: getUserToken()
      });
      if (res.status == 200) {
        setRefetch(!refetch);
        setLoading(false)
        setShowpmodal(false);
        addToast(res?.data?.message, { appearance: 'success' });
      }
      else {
        addToast(res?.data?.message, { appearance: 'error' });
      }
    } else {
      addToast(`Please enter the post description!`, { appearance: 'error' });

    }
  }

  const handleDeletePost = async (data) => {
    let payload = {
      "postId": data?._id
    }
    let res = await ApiServices.delete("/astrologer/post/delete", payload, {
      Authorization: getUserToken()
    })
    if (res.status == 200) {
      setShowDt(false)
      setRefetch(!refetch)
      addToast(res?.data?.message, { appearance: 'success' });
    }
    else {
      addToast(res?.data?.message, { appearance: 'error' });
    }
  }

  const sendReport = async () => {
    if (state?.reasonValue && state?.reasonId) {
      let payload = {
        "postId": postinfo?._id,
        "reportId": state?.reasonId,
        "reportReason": state?.reasonValue
      };
      let res = await ApiServices.post("/astrologer/post/report", payload, {
        Authorization: getUserToken()
      })
      if (res.status == 200) {
        setRefetch(!refetch);
        addToast(res?.data?.message, { appearance: 'success' });
      }
      else {
        addToast(res?.data?.message, { appearance: 'error' });
      }
    } else {
      addToast(`${state?.reasonId ? 'Please enter reason description!' : 'Please select reason!'}`, { appearance: 'error' });

    }

  }
  const renderReportModal = () => {
    return (
      <Modal show={showRModal} size="lg">
        <Modal.Body>
          <ul onChange={e => setState((prevState) => ({ ...prevState, reasonId: e.target.id }))} className="list-group "> {/* list-group-flush */}
            {reasonList && reasonList.map(res => {
              return <li key={res?._id} className="list-group-item">
                <input type="radio" className="form-check-input" id={res?._id} name="optradio" value={res?.title} /> {res?.title}
                <label className="form-check-label" htmlFor="radio1"></label>
              </li>
            })}
          </ul>
          <div>
            <label className="form-label mt-2" >Reason *</label>
            <input type="text" className="form-control" onChange={e => setState((prevState) => ({ ...prevState, reasonValue: e.target.value }))} value={state.reasonValue} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowrmodal(false)} className='as_btn log_btn'>
            Close
        </button>
          <button
            onClick={() => sendReport()}
            className='as_btn log_btn'
          >
            Send Report
        </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderPostModal = () => {
    return (
      <Modal show={showPModal} size="md" centered scrollable>
        <Modal.Body>
          <div className="row bg-light">
            <div className="col-10 pe-0">
              <input type='text' className="form-control" placeholder='Write your thought…' value={postDesc} onChange={(e) => setPostDesc(e.target.value)} autofocus/>
            </div>
            <div className="col-2 d-flex align-items-center" title="Choose post image">
              {uploadFile ?
                <a onClick={() => setUploadFile('')}>
                  <Trash style={{ color: '#B21414', 'font-size': '35px' }} />
                </a>
                :
                <label htmlFor='imageUpload'>
                  <CardImage style={{ color: '#B8BBCC', 'font-size': '35px' }} />
                </label>
              }
            </div>
          </div>
          {uploadFile &&
            <div className="d-flex align-items-center mt-2" >
              <img src={URL.createObjectURL(uploadFile)} alt="post image" className="post_image" style={{ width: '100%', height: 'auto' }} />
            </div>
          }
          <input
            name='postImage'
            type='file'
            id='imageUpload'
            accept='.png, .jpg, .jpeg'
            onChange={(e) => setUploadFile(e?.target?.files[0])}
            className='d-none'
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowpmodal(false)} className='as_btn log_btn'>
            Close
        </button>
        {loading? <button
      
          className='as_btn log_btn'
        >
          Processing...
      </button>: <button
  onClick={() => {handleCreateNewPost(); setLoading(true)}}
  className='as_btn log_btn'
>
  Post
</button>}
         
        </Modal.Footer>
      </Modal>
    )
  }
  const handleShowPerPage = (number) => {
    setPageOffset(0);
    setPerpage(number);
    setRefetch(!refetch);
    window.scrollTo(0,0);
  }

  const renderShowPerPage = () => {
    let numbers = [10, 20, 50, 100];
    return (
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          Show: {perPage}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {numbers && numbers.map(number => {
            return <Dropdown.Item onClick={() => handleShowPerPage(number)} className={`${perPage === number ? 'active' : null}`}>{number}</Dropdown.Item>;
          })}
        </Dropdown.Menu>
      </Dropdown>)
  }

  return (
    <>
      <div className='astrologer_feed_inner mt-3 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-lg-9'>
              <div className='astro_feed_sec'>
                {renderReportModal()}
                {renderPostModal()}
                <div className='pseudo-search'>
                  <div className='input_box'>
                    <img src={userInfo?.profileImage} className='img-fluid user_img' />
                    <input type='text' onClick={e => setShowpmodal(true)} placeholder='Write your thought…' />
                  </div>
                  <button className='btn' type="button" onClick={handleCreateNewPost}>
                    POST
                  </button>
                </div>
                <div>
                  {getFeeds && getFeeds?.length > 0 ? (
                    <div>
                      {getFeeds.map(data => (
                        <div className='card _feed_box mt-4' key={data?._id}>
                          <div className='card-header d-flex align-items-center justify-content-between'>
                            <div className='user_info d-flex align-items-center'>
                              {renderPostImages(data)}
                            </div>
                            {userInfo?._id === (data?.astrologerId?._id || data?.userId?._id) ?
                              <a onClick={() => {setDtkey(data); handleShowDt()}} className="cursor-pointer">
                                <Trash style={{ color: '#B21414' }} />
                              </a>
                              : <a className="cursor-pointer" onClick={() => handleModalShow(data)}>Report</a>}
                          </div>
                          <div className='card-body'>
                            <p>{data?.description}</p>
                            <a onClick={() => navigateToFeeds(data?._id)}>
                              <img
                                src={data?.mediaFile}
                                className='post-img-fluid'
                              />
                            </a>
                          </div>
                          <div className='card-footer'>
                            <ul className='nav'>
                              <li className="cursor-pointer">
                                <span onClick={() => handleLikeCount(data)} >
                                  {data?.likeStatus === false ? (
                                    <Heart />
                                  ) : (
                                      <HeartFill style={{ color: '#B21414' }} />
                                    )} </span>{' '}
                                Like {data?.likeCount}
                              </li>
                              <li className="cursor-pointer">
                                <span onClick={() => navigateToFeeds(data?._id)} >
                                  <Chat />{' '}
                                  Comments {data?.commentCount}
                                </span>
                              </li>
                              <li className="cursor-pointer">
                                {/* <a href='#' >
                                  <CursorFill />
                                  Share
                                </a> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                      <div className='call_history_box my-5'>
                        <p>No data available</p>
                      </div>
                    )}
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between" id="feeds-pagination">
                  {renderShowPerPage()}
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-3'>
              <div className='feeds_requset_sec'>
                <div className='wall_box d-flex align-items-center justify-content-between cursor-pointer'>
                  <PencilSquare className='myWallPencil' htmlFor='mywall-switch' onClick={handleWall}/>
                  <h5 htmlFor='mywall-switch' onClick={handleWall}>My Wall</h5>
                  <Switch
                    isOn={feeds}
                    onColor="#347A16"
                    id='mywall-switch'
                    handleToggle={() => handleWall()}
                  />
                </div>
                {/*<div className='_chat_request mt-4'>
                   <h4>CHAT REQUEST -</h4>
                  <ul>
                    <li className='d-flex align-items-start'>
                      <img
                        src='/images/feed/user.png'
                        className='img-fluid user_img me-2'
                      />
                      <div>
                        <h4>Pankaj Soni</h4>
                        <p>Nov 09, 2022 10.36pm</p>
                        <div className='d-flex align-items-center'>
                          <a href='#' className='accept me-1'>
                            ACCEPT
                          </a>
                          <a href='#' className='reject'>
                            REJECT
                          </a>
                        </div>
                      </div>
                    </li>

                    <li className='d-flex align-items-start'>
                      <img
                        src='/images/feed/user.png'
                        className='img-fluid user_img me-2'
                      />
                      <div>
                        <h4>Neha Shrivastav</h4>
                        <p>Nov 09, 2022 10.36pm</p>
                        <div className='d-flex align-items-center'>
                          <a href='#' className='accept me-1'>
                            ACCEPT
                          </a>
                          <a href='#' className='reject'>
                            REJECT
                          </a>
                        </div>
                      </div>
                    </li>

                    <li className='d-flex align-items-start'>
                      <img
                        src='/images/feed/user.png'
                        className='img-fluid user_img me-2'
                      />
                      <div>
                        <h4>Anshul Shrivastav</h4>
                        <p>Nov 09, 2022 10.36pm</p>
                        <div className='d-flex align-items-center'>
                          <a href='#' className='accept me-1'>
                            ACCEPT
                          </a>
                          <a href='#' className='reject'>
                            REJECT
                          </a>
                        </div>
                      </div>
                    </li>

                    <li className='d-flex align-items-start'>
                      <img
                        src='./images/feed/user.png'
                        className='img-fluid user_img me-2'
                      />
                      <div>
                        <h4>Mohit Tiwari</h4>
                        <p>Nov 09, 2022 10.36pm</p>
                        <div className='d-flex align-items-center'>
                          <a href='#' className='accept me-1'>
                            ACCEPT
                          </a>
                          <a href='#' className='reject'>
                            REJECT
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='_call_request mt-4'>
                  <h4>CALL REQUEST -</h4>

                  <div className='_call_req_inn'>
                    <img
                      src='/images/feed/call_history.png'
                      className='img-fluid'
                    />
                    <h4>Rakesh Soni</h4>
                    <p>Nov 09, 2022 10.36pm</p>
                    <a href='#' className='as_btn mt-4'>
                      CALL HISTORY
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
      backdrop="static" onHide={handleCloseDt} 
      >
      <Modal.Header className="modal-header-hd">
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this post?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="as_btn log_btn" onClick={handleCloseDt}>
          Cancel
        </Button>
        <Button variant="primary" className="as_btn log_btn" onClick={()=>{handleDeletePost(dtkey); }}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>

    </>
  )
}
export default Feeds
