import { useState, useContext, useEffect, useRef } from "react";
import ApiServices from "../../utils/ApiServices";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { Image, Modal, Button, Row, Col } from "react-bootstrap";
import { UserContext } from "../../context/UserProvider";
import { useLocation } from 'react-router-dom';
import { getUserToken, getSocketConnection, getCookeiDetail } from "../../utils/helper";
import ringer from "../../ringtone/mantra.mp3";
import AudioCall from "../../components/astrologercall";
import { SocketContext } from "../../context/socketProvider";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [call, setCall] = useState(false);
  const { logOut, endCallByUserEnd } = useContext(UserContext)
  const [callingData, setCallingData] = useState('');
  const callData = useRef();
  const audio = useRef();
  const endCallByUser = useRef(false);
  const callSocket = useRef(false);
  const [calling, setCalling] = useState(false);
  const callStart = useRef(false)
  const location = useLocation();
  var astroData = getCookeiDetail("astrologer_data");
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const {chatInvocation} = useContext(SocketContext);
  const [data ,setData] = useState('')

  useEffect(() => {
    const checkMediaDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasMicrophone = devices.some(device => device.kind === 'audioinput');
        setHasMicrophone(hasMicrophone);
      } catch (error) {
        console.error('Error checking media devices:', error);
      }
    };

    checkMediaDevices();
  }, []);



  const fetchData = async () => {
    const res = await ApiServices.get(`astrologer/astrologerDetails/${astroData?._id}`)
    if (res.status == 200 && res.data.status == true) {
      const Data = res.data.data[0];
       setData(Data)    
    }
    else {
      console.log("Error:", res);
    }
  }

  const playAudio = () => {
    let newAudio = new Audio(ringer);
    newAudio.play();
    newAudio.loop = true
    audio.current = newAudio
    setTimeout(() => { handelEndCall('no-answer') }, 30000)
  }

  const pauseAudio = () => {
    // audio.current
    audio.current.pause();
    audio.current.currentTime = 0;
    audio.current.loop = false
  }

  //update call status
  const updateCallStatus = async (callStatus) => {
    let data = callData.current;
    pauseAudio()
    if (callStatus !== 'Accept') {
      setCall(false);
      setCalling(false);
      callStart.current = false;
    }
    let payload = {
      "orderId": data.orderId,
      "astrologerId": data.astrologerId,
      "callId": data.callId,
      "status": callStatus
    }
    await ApiServices.post('order/internalCallStatusUpdate', payload,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )

    if (callSocket.current && callStatus !== 'Accept' && !endCallByUser.current) {
      callSocket.current.emit('endCall', data.userId)
    } else if (callStatus === 'Accept' && !endCallByUser.current) {
      callSocket.current.emit('acceptCall', data.userId)
      window.localStorage.setItem('astroCallStartsTime', Date.now());
    }
  }

  //update call status completed
  const updateCallEnd = async (callStatus) => {
    let data = callData.current;
    if (callStatus !== 'completed') pauseAudio()
    let payload = {
      "orderId": data.orderId,
      "astrologerId": data.astrologerId,
      "callId": data.callId,
      "duration": '0',
      "status": callStatus,
      "recordingURL": "http://localhost:8000/order/internalCallEnd"
    }
    if (callSocket.current && !endCallByUser.current) {
      callSocket.current.emit('endCall', data.userId)
    }
    setCall(false)
    setCalling(false);
    await ApiServices.post('order/internalCallEnd', payload,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )
  }

  const handelEndCall = async (callStatus) => {
    if (callStatus === 'Accept') {
      if (hasMicrophone) {
        setCalling(true)
        callStart.current = true
        await updateCallStatus(callStatus)
      } else {
        await updateCallStatus('Cancelled')
        alert('Microphone not found. Please add a Microphone and try again.');
      }
    } else if (callStatus === 'Reject') {
      await updateCallStatus(callStatus)
    } else if (callStatus === 'completed') {
      callStart.current = false
      await updateCallEnd(callStatus)
    } else if (callStatus === 'no-answer') {
      if (!callStart.current) await updateCallEnd(callStatus)
    } else if (callStatus === 'Cancelled') {
      await updateCallStatus(callStatus)
    }
  }

  const fetchCallStatus = async () => {
    const res = await ApiServices.get('order/callStatusAstro',
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )
    if (res.status == 200 && res?.data?.data?.[0]?.agoraToken !== '') {
      let obj = {
        channelName: res.data.data[0].channelName,
        appId: res.data.appId,
        callId: res.data.data[0].agoraToken,
        userId: res.data.data[0].userId?._id,
        orderId: res.data.data[0].orderId,
        profileImage: res.data.data[0].userId.profileImage,
        userName: res.data.data[0].userId.name,
        astrologerId: res.data.data[0].astrologerId._id
      }
      callData.current = obj
      playAudio(obj)
      setCallingData(obj)
      setCall(true)
    }
  }

  const handelEndCallByUser = () => {
    endCallByUser.current = true;
    if (!callStart.current) {
      callStart.current = true;
      pauseAudio();
      handelEndCall('Cancelled');
    }
    else {
      endCallByUserEnd(true)
      handelEndCall('completed')
    }
  }

  useEffect(() => {
    //temprory commented this
    // const socket = getSocketConnection();
    // callSocket.current = socket
    // socket.emit('register', astroData._id)

    // socket.on("listenMessage", () => {
    //   endCallByUserEnd(false)
    //   setTimeout(fetchCallStatus, 1000)
    // })

    // socket.on("endCallMessage", () => {
    //   setTimeout(handelEndCallByUser, 1000)
    // })
    // return () => {
    //   socket.off("listenMessage", () => {
    //     endCallByUserEnd(false)
    //     setTimeout(fetchCallStatus, 1000)
    //   })
    //   socket.off("endCallMessage", () => {
    //     setTimeout(handelEndCallByUser, 1000)
    //   })
    // }

    fetchData()
  }, [])

  const handelLeaveCall = () => {
    handelEndCall('completed')
  }

  return (
    <header className="as_header_wrapper">
      <div className="as_logo">
        <NavLink to="/">
          <img src="/images/logo_withtagline.png" alt=""/>
        </NavLink>
      </div>
      <div className="as_header_detail">
        <div className={`as_menu_wrapper ${menuOpen ? "menu_open" : ""}`}>
          <span className="as_toggle bg_overlay">
            <Image src="/images/svg/menu.svg" alt="" layout="intrinsic" onClick={() => setMenuOpen(true)} />
          </span>
          <div className="as_menu">
            <ul>
              <li className="text-end p-2 d-lg-block d-xl-none" onClick={() => setMenuOpen(false)}>
                <ArrowLeft />
              </li>
              <li>
                <NavLink to="/" className={location.pathname === "/" ? "active" : ""}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" className={location.pathname.match(/^\/profile/) ? "active" : ""}>
                  Profile
                </NavLink>
              </li>
              {data?.allowQuestionAnswered === 'yes'?
              <li>
                <NavLink to="/paidquestions" className={location.pathname.match(/^\/paidquestions/) ? "active" : ""}>
                Paid Questions
                </NavLink>
              </li>:''}
              {/* <li>
                <NavLink to="/order" className={location.pathname.match(/^\/order/) ? "active" : ""}>
                  Order
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/chats" className={`${location.pathname.match(/^\/order/) ? "active" : ""} position-relative`}>
                  <div className={chatInvocation ? "chat-icon-show" : "chat-icon-hide"}></div>
                  Chat
                </NavLink>
              </li> */}


              {/* <li>
                <NavLink to="/product" className={location.pathname.match(/^\/product/) ? "active" : ""}>
                  Product
                </NavLink>
              </li>

                {/* <li>
                <NavLink to="/account" className={location.pathname.match(/^\/account/) ? "active" : ""}>
                  Account
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/transaction" className={location.pathname.match(/^\/transaction/) ? "active" : ""}>
                Call Earnings
                </NavLink>
              </li>
              <li>
                <NavLink to="/chattransaction" className={location.pathname.match(/^\/chattransaction/) ? "active" : ""}>
                Chat Earnings
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/contact" className={location.pathname.match(/^\/contact/) ? "active" : ""}>
                  Contact
                </NavLink>
              </li> */}
              <li>
                <button onClick={logOut} className="as_btn log_btn">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
        <span className="as_body_overlay"></span>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={call}
        onHide={() => handelEndCall('Reject')}
        backdrop={"static"}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Incomming Call :  {callingData?.userName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center align-middle"><span onClick={() => handelEndCall('Accept')} >
              <AudioCall
                callingData={callingData}
                handelLeaveCall={handelLeaveCall}
              /></span>
            </Col>
            {!calling &&
              <Col className="text-center pt-3">
                <img src="/images/decline.gif" alt="loading..." width="150" height="150" onClick={() => handelEndCall('Reject')} title="Reject this call" />
              </Col>
            }
          </Row>
        </Modal.Body>
        {!calling &&
          <Modal.Footer>
            <Button onClick={() => handelEndCall('Reject')} className="as_btn">Close</Button>
          </Modal.Footer>
        }
      </Modal>
    </header>
  );
};

export default Header;
