import React, { useState, useRef, useEffect } from "react";
import { AgoraVideoPlayer, AgoraAudioPlayer } from "agora-rtc-react";
import Timer from "./Timer"

const WebDashboard = (props) => {
  const { users, userProfile, client } = props;

  const style = {
    'width': '50%',
    'margin': 'auto',
  }
  return (
    <div className='d-flex justify-content-center mt-3'>
      <div className="row">
        <div className='col-md-12 col-lg-12 col-12 mb-2'>
          <div>
            {userProfile && userProfile.length > 0 ? <img src={userProfile} style={style} /> : <img src='images/avatar.webp' style={style} />}
          </div>
          {users[0] && (<AgoraAudioPlayer client={client} uid={users[0].uid} />
          )}
        </div>
        <div>
         <Timer/>
        </div>
      </div>
    </div>
  );
};

export default WebDashboard;
