import React from "react";
import Header from "../components/Header";


const Account = () => {
  return (
    <>
      <Header />
      <div className="as_main_wrapper">
        <section className="astrologer_area wallet_area">
          <div className="astrologer_heading mb-5">
            <div className="container">
              <div className="as_wallet_heading">
                <h1>Astrologer Wallet</h1>
                <div className="wallet_prize">
                  <p>AVAILABLE BALANCE</p>
                  <h4>₹240.20</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="add_history">
                  <h2>Transaction HISTORY</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>+50</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                        <tr>
                          <td>+120</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                        <tr>
                          <td>+150</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                        <tr>
                          <td>+50</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                        <tr>
                          <td>+120</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                        <tr>
                          <td>+150</td>
                          <td>15 April 2022, 12:45pm</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button className="as_btn pay_btn">Transfer to Bank</button>
          </div>
        </section>
      </div>
    </>
  );
}
export default Account;