import axios from "axios";
import moment from "moment";
import {useContext, useEffect, useState, useRef} from "react";
import {Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import ChatImage from "../components/chatImage";
import Timer from "../components/chatTimer";
import Header from "../components/Header";
import {SocketContext} from "../context/socketProvider";
import {getCookeiDetail, getUserToken} from "../utils/helper";
import {Modal} from "react-bootstrap";

const ChatWindow = () => {
  const {Socket, userInfo, showModal, hideChatInvocation, hideModal, chatInvocation} = useContext(SocketContext);
  const [chats, setChats] = useState([]);
  // const [userPic, setUserPic] = useState("");
  const [imgData, setImgData] = useState({
    image: "",
    remove: false,
    preview: "",
  });

  const data = getCookeiDetail("astrologer_data");
  const msgRef = useRef(null);
  const mainsRef = useRef(null);
  const lastMsgRef = useRef(null);

  const scrollToBottom = () => {
    if (mainsRef.current && lastMsgRef.current) {
      const parentElement = mainsRef.current;
      const lastDivElement = lastMsgRef.current;
      const scrollOptions = {
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      };
      const parentRect = parentElement.getBoundingClientRect();
      const lastDivRect = lastDivElement.getBoundingClientRect();
      const scrollOffset = lastDivRect.bottom - parentRect.bottom;
      parentElement.scrollTo({
        top: parentElement.scrollTop + scrollOffset,
        ...scrollOptions,
      });
    }
  };

  const handleTermination = () => {
    const sendObj = {
      sender: data._id,
      reciever: userInfo.username,
      userType: "astrologer",
      message: "stop",
    };
    Socket.emit("chat:stop", sendObj);
  };

  const handleSend = e => {
    e.preventDefault();
    let msg = msgRef.current.value;
    let sendObj = {
      sender: data._id,
      reciever: userInfo.username,
      message: msg,
      userType: "astrologer",
      createdAt: new Date().toISOString(),
      imageObj: "",
    };

    if (imgData.image) {
      let sendImgObj = {
        filename: imgData.image.filename,
        data: imgData.image.data,
        mimetype: imgData.image.mimetype,
      };
      Object.assign(sendObj, {imageObj: sendImgObj});
    }

    Socket.emit("message:send", sendObj, date => {
      Object.assign(sendObj, {createdAt: date});
    });
    setImgData({remove: true, image: "", preview: ""});
    setChats([...chats, sendObj]);
    msgRef.current.value = "";
  };

  const fetchMessages = async () => {
    const response = await axios.post(process.env.REACT_APP_CHAT_SERVER + "/rest_api/getMessage", {
      sender: data._id,
      reciever: userInfo.username,
    });

    if (response.status === 200 && response.data.status === 1) {
      setChats([...chats, ...response.data.data]);
    }
  };

  // to get user profile pic
  // const fetchUserDetails = async () => {
  //   const res = await ApiServices.get(`/users/userDetail`, {
  //     Authorization: getUserToken(), //but this is astrologer token
  //   });
  // };

  const imageRender = (image, index) => {
    if (image && image.data) {
      return <img src={image.data} alt={`${index}-im`} />;
    } else if (image) {
      let images = image.includes('images') ? process.env.REACT_APP_CHAT_SERVER+image : image
      return <img src={images} alt={`${index}-im`} />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    function handleMessage(data) {
      setChats([...chats, data]);
    }
    Socket.on("message:get", handleMessage);
    scrollToBottom();
    return () => {
      Socket.off("message:get", handleMessage);
    };
  }, [Socket, chats]);

  const handleImage = (data, file) => {
    setImgData(prev => ({...prev, image: data, preview: file}));
  };

  const formateDate = date => {
    let options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
    const newdate = new Date(date);
    return newdate.toLocaleTimeString("en-IN", options);
  };

  useEffect(() => {
    hideModal();
    fetchMessages();
  }, []);

  const onModalHideNavigation = () => {
    hideChatInvocation();
  };

  return (
    <section className="astrologer_area">
      <Modal show={showModal} size="lg" onHide={onModalHideNavigation} centered className="text-white">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>The user has intentionally disconnected the chat. Please proceed accordingly and feel free to assist any other users who may require your guidance. Thank you for your understanding and for providing valuable insights.</Modal.Body>
      </Modal>
      <div className="container pt-4">
        <div className="chat_window_inner">
          <div className="msger-header">
            <div className="msger-header-title d-flex align-item-center">
              <img src="/images/chat_UserImage.jpg" className="img-fluid mr-3" alt="chat-profile-pic" />
              <div className="user_name">
                <h4 className="text-capitalize">{userInfo.name}</h4>
                <Timer />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5>
                <button className="mx-2 bg-transparent border border-light rounded text-white px-3 py-1" onClick={handleTermination}>
                  End Chat
                </button>
              </h5>
            </div>
          </div>
          <main className="msger-chat" ref={mainsRef}>
            {chats.map((item, index) => {
              let checks = item.sender === data._id;

              //---Right now getting astrologer image from store data in cookie
              // (no source of astrologer image till now)
              let userImage = checks ? data.profilePic : userInfo.profilePic;
              return (
                <div key={index} ref={lastMsgRef} className={`msg ${checks ? "right-msg" : "left-msg"}`}>
                  <div className="msg-img">
                    <img src={userImage ? userImage : `/favicon.png`} className="img-fluid" alt="chat-inside-pic" />
                  </div>
                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name">{checks ? data.name : userInfo.name}</div>
                      <div className="msg-info-time">{formateDate(item.createdAt)}</div>
                    </div>
                    <div className="msg-text">{item.message}</div>
                    {imageRender(item.imageObj, index)}
                  </div>
                </div>
              );
            })}
          </main>
          <form className="msger-inputarea bg-white">
            <input type="text" className="msger-input bg-transparent border as_hideoutline" placeholder="Enter your message..." ref={msgRef} />
            <ChatImage handleImage={handleImage} preview={imgData.preview ? imgData.preview : ""} />
            <button type="submit" className="msger-send-btn" onClick={handleSend}>
              send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

const NotificationModel = ({showchatWindow}) => {
  const {Socket, userInfo, handleChatWindow, hideChatInvocation} = useContext(SocketContext);
  const data = getCookeiDetail("astrologer_data");
  const navigate = useNavigate();

  const handleResponse = cmd => {
    Socket.emit("user:notification", {sender: data._id, reciever: userInfo.username, userData: cmd});
    if (cmd === "Reject") {
      navigate("/");
      hideChatInvocation();
    } else {
      handleChatWindow(false);
      showchatWindow();
      Socket.emit("chat:start", {
        sender: data._id,
        reciever: userInfo.username,
      });
    }
  };

  return (
    <section className="astrologer_area">
      <div className="astrologer_heading mb-5">
        <div className="container">
          <h1>Chat Confirmation</h1>
        </div>
      </div>
      <article className="d-flex justify-content-center">
        <Card className="bg-white w-50">
          <Card.Header className="chat-popup-header">{userInfo.name}</Card.Header>
          <Card.Body>
            <div className="d-flex flex-column">
              <p className="bg-light d-flex justify-content-around">
                <span className="text-danger fw-bold w-25">Birth Place</span>
                <span className="fw-bold w-25">{userInfo.birthplace}</span>
              </p>
              <p className="bg-light d-flex justify-content-around">
                <span className="text-danger fw-bold w-25">Area Of Problem</span>
                <span className="fw-bold w-25">{userInfo.area_of_problem}</span>
              </p>
              <p className="bg-light d-flex justify-content-around">
                <span className="text-danger fw-bold w-25">Problem</span>
                <span className="fw-bold w-25">{userInfo.message}</span>
              </p>
              <p className="bg-light d-flex justify-content-around">
                <span className="text-danger fw-bold w-25">Date Of Birth</span>
                <span className="fw-bold w-25">{moment(userInfo.user_dob).format("LL")}</span>
              </p>
              <p className="bg-light d-flex justify-content-around">
                <span className="text-danger fw-bold w-25">DOB Time</span>
                <span className="fw-bold w-25">{moment(userInfo.userdob_time).format("LT")}</span>
              </p>
            </div>
          </Card.Body>
          <div className="d-flex justify-content-center gap-2 my-2">
            <button className="btn bg-success text-white" onClick={() => handleResponse("Accept")}>
              Accept
            </button>
            <button className="btn bg-danger text-white" onClick={() => handleResponse("Reject")}>
              Reject
            </button>
          </div>
        </Card>
      </article>
    </section>
  );
};

const Chats = () => {
  const {hideChat} = useContext(SocketContext);
  const [showModel, setShowModel] = useState(hideChat);

  const showchatWindow = () => {
    setShowModel(false);
  };
  return (
    <>
      <Header />
      {showModel ? <NotificationModel showchatWindow={showchatWindow} /> : <ChatWindow />}
    </>
  );
};

export default Chats;
