import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Header from "../components/Header";
import { getUserToken } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import moment from "moment";

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState()
  const { orderid } = useParams();

  const fetchUserData = useCallback(async () => {
    const res = await ApiServices.get(`astrologer/astrologerOrderDetail/${orderid}`,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )

    if (res.status == 200 && res.data.status) {
      const userOrder = res?.data?.orderData[0];
      setUserDetails(userOrder);
    }
  }, [orderid])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper">
        <div className="astrologer_heading mb-5">
          <div className="container">
            <h1>Calling Details</h1>
          </div>
        </div>
        <div className="support_chat_form">
          <h4>Details</h4>
          <div className="support_Chat_inner user_details_table">
            <div className="row">
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Customer's Name</td>
                    <td className="text-capitalize">{userDetails?.userDetail[0].name}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td>{moment(userDetails?.birthTime).format('DD/MM/YY')}</td>
                  </tr>
                  <tr>
                    <td>Birth Place</td>
                    <td className="text-capitalize">{userDetails?.birthPlace}</td>
                  </tr>
                  <tr>
                    <td>Birth Time</td>
                    <td>{moment(userDetails?.birthTime).format('LTS')}</td>
                  </tr>
                  <tr>
                    <td>Area of Problem</td>
                    <td className="text-capitalize">{userDetails?.areaOfProblem}</td>
                  </tr>
                  <tr>
                    <td>Problem</td>
                    <td><p className="text-capitalize">{userDetails?.problem}</p></td>
                  </tr>
                  <tr>
                    <td>Scheduled Date</td>
                    <td>{moment(userDetails?.orderInfo?.scheduleTime).format('DD/MM/YY')}</td>
                  </tr>
                  <tr>
                    <td>Scheduled Time</td>
                    <td>{moment(userDetails?.orderInfo?.scheduleTime).format('LT')}</td>
                  </tr>
                  {/* <tr>
                    <td>Duration</td>
                    <td>{userDetails?.productTime}</td>
                  </tr>
                  <tr>
                    <td>Meeting Link</td>
                    <td><a target="_blank" href={"/"+userDetails?.orderInfo?.link}><button className="as_btn log_btn">Join</button></a></td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
export default UserDetails;