import { useEffect, useState } from "react";
import Header from "../components/Header";
import ApiServices from "../utils/ApiServices";
import { getUserToken } from "../utils/helper";
import { Table } from "react-bootstrap";
import moment from "moment";

const Calls = () => {
  const [getOrder, setGetOrder] = useState();
  const fetchData = async () => {
    const res = await ApiServices.get(`astrologer/getAstrologerOrders`,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )

    if (res.status == 200 && res.data.status == true) {
      const astrologerOrder = res?.data?.data;
      const filteredOrderData = astrologerOrder.filter(order => order.orderType === "directCall");
      setGetOrder(filteredOrderData)
    }
    else {
      console.log("ERROR", res)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper">
        <div className="astrologer_heading mb-lg-5 mb-md-5 mb-3">
          <div className="container">
            <h1>Your Calls</h1>
          </div>
        </div>
        <div className="support_chat_form">
          <h4>Details</h4>
          <div className="support_Chat_inner">
            <div className="row">
              <div>
                {getOrder && getOrder?.length > 0
                  ?
                  <div>
                    <div className='table-responsive orderTable'>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Date</th>
                            <th>Call Duration</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getOrder.map((order) =>
                            <tr key={order?._id}>
                              <td className="text-capitalize">{order?.userId?.name}</td>
                              <td>{moment(order?.updatedAt).format('MMMM Do YYYY')}</td>
                              <td>{order?.duration} min</td>
                              <td className="text-capitalize">{order?.userCallStatus}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  : <div className="call_history_box my-5">
                    <p>No Calling data available</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default Calls;