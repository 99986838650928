import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {socket} from "../socket";
import {getCookeiDetail} from "../utils/helper";
import {UserContext} from "./UserProvider";
const SocketContext = createContext();

const SocketProvider = ({children}) => {
  const {isLoggedIn} = useContext(UserContext);
  const [Socket, setSocket] = useState(null);
  const [connected, setConneted] = useState(false);
  const [chatInvocation, setChatInvocation] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const {addToast} = useToasts();
  const [hideChat, setHideChat] = useState(true);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const intervalGId = useRef(0);

  const hideModal = () => {
    setShowModal(false);
  };

  const settingUpGID = val => {
    intervalGId.current = val;
  };

  const hideChatInvocation = () => {
    setChatInvocation(false);
    setUserInfo("");
  };

  const handleChatWindow = value => {
    setHideChat(value);
  };

  const setUpMinutes = value => {
    setMinutes(value);
  };

  const setUpSeconds = value => {
    setSeconds(value);
  };

  function userDisconnect(data) {
    clearInterval(intervalGId.current);
    setUserInfo("");
    setHideChat(true);
    setSeconds(0);
    setMinutes(0);
    if (data === "userTerminate") {
      setShowModal(true);
    } else {
      setChatInvocation(false);
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      socket.connect();
      setConneted(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    function userRespose(data) {
      setChatInvocation(true);
      setUserInfo(data);
    }

    function errorHandling({error}) {
      addToast(error, {appearance: "error"});
    }

    if (connected) {
      const data = getCookeiDetail("astrologer_data");
      if (data) {
        let astrologerid = data._id;
        socket.emit("register", {
          usertype: "astrologer",
          astrologerid,
        });
        setSocket(socket);
      }
      socket.on("user:notification:response", userRespose);
      socket.on("chat:stopped", userDisconnect);
      socket.on("error:socket", errorHandling);
    }
    return () => {
      socket?.off("user:notification:response", userRespose);
      socket?.off("chat:stopped", userDisconnect);
      socket?.off("error:socket", errorHandling);
    };
  }, [connected]);

  return (
    <SocketContext.Provider
      value={{
        Socket,
        minutes,
        setUpMinutes,
        seconds,
        setUpSeconds,
        hideChat,
        settingUpGID,
        chatInvocation,
        userInfo,
        intervalGId,
        hideModal,
        showModal,
        handleChatWindow,
        hideChatInvocation,
      }}>
      {children}
    </SocketContext.Provider>
  );
};

export {SocketProvider, SocketContext};
