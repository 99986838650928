import React, {useContext, useEffect, useState} from "react";
import {SocketContext} from "../../context/socketProvider";

const Timer = () => {
  const [intervalID, setIntervalID] = useState(0);
  const {Socket, seconds, setUpSeconds, setUpMinutes, minutes, chatInvocation, settingUpGID} = useContext(SocketContext);

  const handleTime = ({totalMinutesLeft}) => {
    const tMinutes = totalMinutesLeft.split(":");
    let minLeft = parseInt(tMinutes[0]);
    let secondsLeft = parseInt(tMinutes[1]);

    let intervals = setInterval(() => {
      if (secondsLeft === 0 && minLeft === 0) {
        clearInterval(intervals);
        return;
      }

      if (secondsLeft === 0 && minLeft > 0) {
        secondsLeft = 59;
        minLeft = minLeft - 1;
        setUpSeconds(secondsLeft);
        setUpMinutes(minLeft);
        return;
      }

      secondsLeft = secondsLeft - 1;
      setUpSeconds(secondsLeft);
    }, 1000);
    setIntervalID(intervals);
    settingUpGID(intervals);
  };

  useEffect(() => {
    Socket.on("chat:started", handleTime);
    return () => {
      Socket.off("chat:started", handleTime);
    };
  }, [Socket]);

  useEffect(() => {
    return () => {
      if (chatInvocation) return;

      clearInterval(intervalID);
    };
  }, [intervalID]);

  return (
    <h5>
      Duration: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </h5>
  );
};

export default Timer;
