import { createClient, createMicrophoneAudioTrack } from "agora-rtc-react";

const config = {
  mode: "rtc",
  codec: "vp8",
};

function useAgora() {
  let useClient;
  let useMicrophoneAudioTrack;
  if (typeof window !== "undefined" && !useClient && !useMicrophoneAudioTrack) {
    useClient = createClient(config);
    useMicrophoneAudioTrack = createMicrophoneAudioTrack();
  }
  return [useClient, useMicrophoneAudioTrack];
}

export default useAgora;
