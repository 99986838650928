import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import Switch from "../components/ToggleButton";
import { getUserToken } from "../utils/helper";
import { getCookeiDetail } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import QuestFeeds from "../components/QuestFeeds";


const PaidQuestions = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [user, setUser] = useState();
  const [count ,setCount] = useState()
  const [hdtype ,setHdtype] = useState()
  const data = getCookeiDetail("astrologer_data")
  const fetchData = async () => {
    const res = await ApiServices.get(`astrologer/astrologerDetails/${data._id}`)
    if (res.status == 200 && res.data.status == true) {
      const Data = res.data.data[0];
      setUser(Data);      
      if (Data.availability == 2) {
        setIsAvailable(false)
      }
      else {
        setIsAvailable(true)
      }

    }
    else {
      console.log("Error:", res);
    }
  }


  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper">
        <div className="astrologer_heading">
          <div className="container d-flex justify-content-between">
            <h4>Paid { hdtype === true?"Answered":"Questions"} ({count})
                </h4>
            <div className="d-flex align-items-center flex-column flex-lg-row flex-md-row">
             
            </div>
          </div>
        </div>
        <div>
          <QuestFeeds userInfo={user} setCount={setCount} setHdtype={setHdtype}/>
        </div>

      </section>
    </>
  );
};

export default PaidQuestions;
