import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useEffect, useState, useRef } from "react";
import AstSelect from "../AstSelect";
import ApiServices from "../../utils/ApiServices";
import "react-datepicker/dist/react-datepicker.css";
import { replaceDataKey } from "../../utils/helper";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import Toast from 'react-bootstrap/Toast';

function CommonForm(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showA, setShowA] = useState(false);
  const [formType, setFormType] = useState("");
  const { formType: propsFormType = "", submitForm = null, formData = {}, verifymsg = "" } = props;
  const { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm();
  const toggleShowA = (val) => setShowA(val);
  const [msgError, setMsgError] = useState();
  const [otpEmail, setOtpEmail] = useState();
  

  useEffect(() => {
    if (propsFormType && propsFormType !== "") {
      setFormType(propsFormType)
    }
  }, [propsFormType])

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const password = useRef({});
  password.current = watch("password", "");

  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  useEffect(() => {
    if (formType === "Edit_profile_page") {
      const defaultData = formData.astrologerDetail;
      if (defaultData) {

        let productData = {};
        defaultData.products
          && defaultData.products.length > 0
          && defaultData.products.map((item, i) => {
            productData[item.productId?.name] = item.price;
          })

        let defaultValues = {
          ...productData,
          name: defaultData?.name,
          email: defaultData?.email,
          contactNumber: defaultData?.contactNumber,
          address: defaultData?.address,
          experience: defaultData?.experience,
          aboutMe: defaultData?.aboutMe,
          specialty: formData?.defaultSpeciality,
          languages: formData?.defaultLanguage,
          callPricePerMinute: defaultData?.callPricePerMinute,
          chatPricePerMinute: defaultData?.chatPricePerMinute
        }
        
        reset({ ...defaultValues });
      }
    }
    if (formType === "Otp_form") {
      reset();
    }
  }, [formType])

  const onSubmit = (data) => {
    if (formType !== "Edit_profile_page") {
      reset();
    }

    if (formType === "Otp_form") {
      submitForm(data);
    }
    if (formType === "Edit_profile_page") {
      data.languages = replaceDataKey(data.languages, "create_form")
      data.specialty = replaceDataKey(data.specialty, "create_form")
      data.products = [];
      if (formData?.productDetails && formData?.productDetails.length > 0) {
        formData?.productDetails.forEach(e => {
          data.products.push({ productId: e._id, price: data[e.name] })
          delete data[e.name]
        });
      }
      submitForm(data);
    }

    if (formType === "forgetPassword_form") {
      handleForgetPassword(data.email);
    }

    if (formType === "resetPassword_form") {
      handleSubmitResetPwd(data.otp, data.password);
    }

    if (formType === "change_password") {
      submitForm(data);
      reset();
    }

  }

  const handleResendOTP = async () => {
    let payload = {
      email: formData.email
    }
    const res = await ApiServices.post("astrologer/resendOtp", payload)
    if (res.status == 200 && res.data.status == true) {
      formData.errorResponse = { status: res.data.status, message: res.data.message };
    } else {
      formData.errorResponse = { status: res.data.status, message: res.data.message };
      console.log("OTP Error:", res);
    }
    toggleShowA(true);
  }

  const handleForgetPassword = async (email) => {
    setMsgError(null);

    let payload = {
      email: email
    }
    setOtpEmail(email);
    const res = await ApiServices.post("astrologer/forgotPassword", payload)
    if (res.status == 200 && res.data.status == true) {
      setMsgError({ status: res.data.status, message: res.data.message });
      setFormType('resetPassword_form');
    }
    else {
      setMsgError({ status: res.data.status, message: res.data.message });
      console.log("OTP Error:", res.data.message);
    }
    toggleShowA(true);
  }

  const handleSubmitResetPwd = async (otp, password) => {
    setMsgError(null);
    let payload = {
      email: otpEmail,
      otp: otp,
      password: password
    }
    const response = await ApiServices.post("astrologer/resetPassword", payload)
    if (response.status == 200 && response.data.status == true) {
      setFormType('Login_Form');
      // setMsgError({ status: response.data.status, message: response.data.message });
      formData.errorResponse = { status: response.data.status, message: response.data.message }
    }
    else {
      // setMsgError({ status: response.data.status, message: response.data.message });
      formData.errorResponse = { status: response.data.status, message: response.data.message }

      console.log("OTP Error:", response.data.message);
    }
    toggleShowA(true)

  }

  const handleResetPwdResendOTP = async () => {
    if (msgError) {
      msgError.message = null;
    }
    formData.errorResponse = null;
    let payload = {
      email: otpEmail
    }
    const res = await ApiServices.post("astrologer/forgotPassResendOtp", payload)
    if (res.status == 200 && res.data.status == true) {
      formData.errorResponse = { status: res.data.status, message: res.data.message };
    } else {
      formData.errorResponse = { status: res.data.status, message: res.data.message };
      console.log("OTP Resend Error:", res);
    }
    toggleShowA(true);
  }

  const handleFormType = () => {
    setFormType("Login_Form");
  }


  //...................Login Form..........

  if (formType === "Login_Form") {
    return (
    <>
      <div className="as_login_fgtPwd" style={{color: "black",textAlign:"center"}}>
        <p> User Login</p>
      </div>
      <div className="login_form">
        <form onSubmit={handleSubmit(submitForm)}>
          {formData.isLoading && <div className="preloaderOverlay"><div className="overlayLoader">
            <div className="spinner-border" role="status"></div></div></div>}
          <div className="form-group">
            <input
              type="text"
              {...register("username", {
                required: true,
                pattern: /^[a-zA-Z0-9\s._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\s]{2,4}$/
              })}
              className="form-control "
              placeholder="Enter Email ID Here"
            />
            {errors.username?.type === "required" && (
              <p className="text-danger">Email is required.</p>
            )}
            {errors.username?.type === "pattern" && (
              <p className="text-danger">Invalid Email.</p>
            )}
          </div>
          <div className="form-group ">
            <input
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                maxLength: 50,
                minLength: 8
              })}
              className="form-control"
              type={passwordShown ? "text" : "password"}
              placeholder="Enter Password Here"
            />
            <span onClick={togglePasswordVisiblity} className="shownpwd">{passwordShown ? <EyeFill /> :  <EyeSlashFill />}</span>
            {errors.password?.type === "required" && (
              <p className="text-danger">Password is required.</p>
            )}
            {errors.password?.type === "pattern" && (
              <p className="text-danger">Password must have one small letter ,capital letter, digit and special character.</p>
            )}
            {errors.password?.type === "maxLength" && (
              <p className="text-danger">Password must have maximum length of 50 characters.</p>
            )}
            {errors.password?.type === "minLength" && (
              <p className="text-danger">Password must have at least 8 characters.</p>
            )}

            
          </div>
          <div className="form-group">
            <div className="as_login_data">
              {/* <label>Remember me
                <input type="checkbox" name="as_remember_me" value="" />
                <span className="checkmark"></span>
              </label> */}
              <div className="as_login_fgtPwd">
                <p onClick={() => {
                  setFormType('forgetPassword_form')
                }}>Forgot password ?</p></div>
            </div>
          </div>
          <div className="text-center mt-3">
            <button type="submit" onClick={() => {
              formData.errorResponse = null;
              toggleShowA(true)
            }} className="as_btn">login</button>
          </div>
          {formData?.errorResponse &&
            <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={5000} bg={formData?.errorResponse?.status || msgError?.status ? 'success' : 'danger'} className="otpfixed_toast">
              <Toast.Header closeButton>
              </Toast.Header>
              <Toast.Body>{formData?.errorResponse?.message ? formData?.errorResponse?.message : msgError?.message ? msgError?.message : ""}</Toast.Body>
            </Toast>}
        </form>
        <p className="text-center as_margin0 as_padderTop20">Create An Account ? <a href="/signup" className="as_orange as_signup">SignUp</a></p>
      </div>
      </>
    );
   
  }

  //........SignUp Form..................

  if (formType === "Signup_Form") {
    return (
      <>
      <div className="as_login_fgtPwd" style={{color: "black",textAlign:"center"}}>
        <p> SignUp</p>
      </div>
      <div className="login_form">
        <form onSubmit={handleSubmit(submitForm)}>
          {formData.isLoading && <div className="preloaderOverlay"><div className="overlayLoader">
            <div className="spinner-border" role="status"></div></div></div>}
          <div className="form-group">
            <input
              {...register("name", {
                required: true,
                pattern: /^[a-zA-Z\s]+(([',. -][a-zA-Z \s])?[a-zA-Z\s]*)*$/
              })}
              className="form-control"
              placeholder="Enter Fullname"
            />
            {errors.name?.type === "required" && (
              <p className="text-danger">Name is required.</p>
            )}
            {errors.name?.type === "pattern" && (
              <p className="text-danger">Invalid Name.</p>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9\s._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\s]{2,4}$/
              })}
              className="form-control"
              placeholder="Enter Email ID"
            />
            {errors.email?.type === "required" && (
              <p className="text-danger">Email is required.</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="text-danger">Invalid Email.</p>
            )}
          </div>
          <div className="form-group">
            <input
              {...register("contactNumber", {
                required: true,
                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                maxLength: 10,
                minLength: 10
              })}
              className="form-control"
              placeholder="Enter Contact Number"
              type="number"
            />
            {errors.contactNumber?.type === "required" && (
              <p className="text-danger">Contact Number is required.</p>
            )}
            {errors.contactNumber?.type === "pattern" && (
              <p className="text-danger">Invalid Number.</p>
            )}
            {errors.contactNumber?.type === "maxLength" && (
              <p className="text-danger">Contact Number must be 10 digits.</p>
            )}
            {errors.contactNumber?.type === "minLength" && (
              <p className="text-danger">Contact Number must be 10 digits.</p>
            )}
          </div>
          <div className="form-group">
            <input
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                maxLength: 50,
                minLength: 8
              })}
              className="form-control"
              type="password"
              placeholder="Enter Password"
            />
            {errors.password?.type === "required" && (
              <p className="text-danger">You must specify a password.</p>
            )}
            {errors.password?.type === "pattern" && (
              <p className="text-danger">Password must have one small letter ,capital letter, digit and special character.</p>
            )}
            {errors.password?.type === "maxLength" && (
              <p className="text-danger">Password must have maximum length of 50 characters.</p>
            )}
            {errors.password?.type === "minLength" && (
              <p className="text-danger">Password must have at least 8 characters.</p>
            )}
          </div>
          <div className="form-group">
            <input
              {...register("password_repeat", {
                required: true,
                validate: value =>
                  value === password.current || "Password and confirm password does not match"
              })}
              className="form-control"
              type="password"
              placeholder="Enter Confirm Password "
            />
            {errors.password_repeat?.type === "required" && (
              <p className="text-danger">Confirm Password is required.</p>
            )}
            {errors.password_repeat && (
              <p className="text-danger">{errors.password_repeat.message}.</p>
            )}
          </div>
          {/* <div className="form-group">
            <select
              {...register("gender", { required: true })}
              className="form-control"
            >
              <option value="">Select Gender</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && (
              <p className="text-danger">Gender is required.</p>
            )}
          </div> */}
          <div className="text-center mt-3">
            <button type="submit" onClick={() => toggleShowA(true)} className="as_btn">Submit</button>
          </div>
          {formData?.errorResponse &&
            <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={5000} bg={formData?.errorResponse?.status ? 'success' : 'danger'} className="signup_toast">
              <Toast.Header closeButton>
              </Toast.Header>
              <Toast.Body>{formData?.errorResponse?.message ? formData?.errorResponse?.message : ""}</Toast.Body>
            </Toast>}
        </form >
        <p className="text-center as_margin0 as_padderTop20">Already have An Account ? <a href="/login" className="as_orange as_signup">Login</a></p>
      </div >
      </>
    );
  }

  //.........Edit Profile Page............

  if (formType === "Edit_profile_page") {
    return (
      <div className="login_form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="edit_profile_box">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Full Name</label>
                  <div className="as_input_feild">
                    <input
                      {...register("name", {
                        required: true,
                        pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                      })}
                      type="text"
                      className="form-control"
                      placeholder="Aman"
                    />
                    {errors.name?.type === "required" && (
                      <p className="text-danger">Name is required.</p>
                    )}
                    {errors.name?.type === "pattern" && (
                      <p className="text-danger">Invalid Name.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Email ID</label>
                  <div className="as_input_feild">
                    <input
                      {...register("email", {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                      })}
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                    />
                    {errors.email?.type === "required" && (
                      <p className="text-danger">Email is required.</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p className="text-danger">Invalid Email.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="datetime" className="required">Date of Birth</label>
                  <div className="as_input_feild">
                    <Controller
                      control={control}
                      name="DOB"
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="05/10/1980"
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          maxDate={new Date()}
                          className="form-control as_datepicker"
                          placeholder="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      )}
                      defaultValue={formData?.astrologerDetail?.DOB ? new Date(formData?.astrologerDetail?.DOB) : ""}
                    />
                    <span><img src="./images/svg/date.svg" alt="" /></span>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Contact Number</label>
                  <div className="as_input_feild">
                    <input
                      {...register("contactNumber", {
                        required: true,
                        pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                        maxLength: 10,
                        minLength: 10
                      })}
                      type="number"
                      className="form-control"
                      placeholder="9874563210"
                    />
                    {errors.contactNumber?.type === "required" && (
                      <p className="text-danger">Contact Number is required.</p>
                    )}
                    {errors.contactNumber?.type === "pattern" && (
                      <p className="text-danger">Invalid Number.</p>
                    )}
                    {errors.contactNumber?.type === "maxLength" && (
                      <p className="text-danger">Contact Number must be 10 digits.</p>
                    )}
                    {errors.contactNumber?.type === "minLength" && (
                      <p className="text-danger">Contact Number must be 10 digits.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Address</label>
                  <div className="as_input_feild">
                    <input
                      {...register("address", { required: true })}
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                    />

                    <span><img src="./images/svg/map1.svg" alt="" /></span>

                  </div>
                  {errors.address?.type === "required" && (
                    <p className="text-danger">Address is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Language</label>
                  <div className="as_input_feild">
                    <Controller
                      control={control}
                      name="languages"
                      render={({ field }) => (
                        <AstSelect
                          cClass="ast_default_select redclor5574547 text-capitalize"
                          cClassPrefix="ast_select"
                          selectOptions={formData?.languageList}
                          selectPlaceholder="Select Languages"
                          changeFn={(data) => {
                            field.onChange(data)
                          }}
                          multi={true}
                          selected={formData?.defaultLanguage}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.languages && (
                      <p className="text-danger">Languages is required.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Speciality</label>
                  <div className="as_input_feild">
                    <Controller
                      control={control}
                      name="specialty"
                      render={({ field }) => (
                        <AstSelect
                          cClass="ast_default_select text-capitalize"
                          cClassPrefix="ast_select"
                          selectOptions={formData?.specialitylist}
                          selectPlaceholder="Select Speciality"
                          changeFn={(data) => {
                            field.onChange(data);
                          }}
                          multi={true}
                          selected={formData?.defaultSpeciality}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.specialty && (
                      <p className="text-danger">Speciality is required.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Experience</label>
                  <div className="as_input_feild">
                    <input
                      {...register("experience", {
                        required: true,
                      })}
                      type="text"
                      className="form-control"
                      placeholder="Enter Experience"
                    />
                    {errors.experience?.type === "required" && (
                      <p className="text-danger">Experience is required.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Call per minute price</label>
                  <div className="as_input_feild">
                    <input
                      {...register("callPricePerMinute", { required: true })}
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Enter Call per minute price"
                    />
                  </div>
                  {errors.callPricePerMinute?.type === "required" && (
                    <p className="text-danger">Call Price is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="required">Chat per minute price</label>
                  <div className="as_input_feild">
                    <input
                      {...register("chatPricePerMinute", { required: true})}
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Enter Chat per minute price"
                    />
                  </div>
                  {errors.chatPricePerMinute?.type === "required" && (
                    <p className="text-danger">Chat Price is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <label>About Me</label>
                  <div className="as_input_feild">
                    <textarea
                      {...register("aboutMe", { required: true })}
                      type="text"
                      className="form-control"
                      placeholder="About YourSelf"
                    />
                    {errors.aboutMe?.type === "required" && (
                      <p className="text-danger">About me is required.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_edit text-center as_padderTop40">
            {/* {props?.loading?<button type="submit" className="as_btn log_btn mt-3" 
            >Processing...</button>: */}
            <button type="submit" className="as_btn log_btn mt-3" onClick={() => {
                formData.errorResponse = null
                toggleShowA(true)
                props?.setLoading(true)
              }}>{props.errorMs ? "Processing..." : "Update Profile"}</button>
              
            </div>
            {formData?.errorResponse && <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={3000} bg={formData?.errorResponse?.status ? 'success' : 'danger'} className="fixed_toast">
              <Toast.Body>{formData?.errorResponse?.message ? formData?.errorResponse?.message : ""}</Toast.Body>
            </Toast>}
          </div>
        </form>
      </div>
    );
  }

  //.............OTP Verification...................

  if (formType === "Otp_form") {
    return (
      <div className="login_form">
        <form onSubmit={handleSubmit(onSubmit)}>
          {formData.isLoading && <div className="preloaderOverlay"><div className="overlayLoader">
            <div className="spinner-border" role="status"></div></div></div>}
          <div className="form-group">
            <input
              {...register("otp", {
                required: true,
                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                maxLength: 4,
                minLength: 4
              })}
              className="form-control"
              placeholder="Enter OTP"
              type="number"
            />
            {errors.otp?.type === "required" && (
              <p className="text-danger">OTP is required.</p>
            )}
            {errors.otp?.type === "pattern" && (
              <p className="text-danger">OTP must be numbers only.</p>
            )}
            {errors.otp?.type === "maxLength" && (
              <p className="text-danger">OTP must be 4 digits.</p>
            )}
            {errors.otp?.type === "minLength" && (
              <p className="text-danger">OTP must be 4 digits.</p>
            )}
          </div>
          <div className="otp">
            <p onClick={handleResendOTP}>Resend OTP</p>
          </div>
          <div className="text-center mt-3">
            <button type="submit" className="as_btn" onClick={() => {
              // formData.errorResponse = null;
              toggleShowA(true);
            }
            }>Submit</button>
          </div>
          {formData?.errorResponse &&
            <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={4000} bg={formData?.errorResponse?.status ? 'success' : 'danger'} className="otpfixed_toast">
              <Toast.Body>{formData?.errorResponse?.message ? formData?.errorResponse?.message : ""}</Toast.Body>
            </Toast>}
          <div className="verifymsgg">
            {verifymsg && verifymsg ?
              <p>{verifymsg}</p> : ""
            }
          </div>
        </form>
      </div>
    );
  }

  //.....Forget Password............

  if (formType === "forgetPassword_form") {
    return (
      <>
      <div className="as_login_fgtPwd" style={{color: "black",textAlign:"center"}}>
        <p> Forgot Password</p>
      </div>
      <div className="login_form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              type="text"
              {...register("email", {
                required: true,
                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
              })}
              className="form-control"
              placeholder="Enter Email ID Here"
            />
            {errors.email?.type === "required" && (
              <p className="text-danger">Email is required.</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="text-danger">Invalid Email.</p>
            )}
          </div>
          <div className="text-center mt-3">
            <button type="submit" className="as_btn" onClick={() => {
              setMsgError(null);
              // toggleShowA(true)
            }}>Submit</button>
          </div>
          {msgError &&
            <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={4000} bg={msgError?.status ? 'success' : 'danger'} className="otpfixed_toast">
              <Toast.Body>{msgError?.message ? msgError?.message : ""}</Toast.Body>
            </Toast>}
        </form>
        <p className="text-center as_margin0 as_padderTop20">Back to <span onClick={handleFormType} className="as_orange as_signup backtologin">Login</span></p>
      </div>
      </>
    );
  }

  //.........Reset Password..................

  if (formType === "resetPassword_form") {
    return (
      <>
      <div className="as_login_fgtPwd" style={{color: "black",textAlign:"center"}}>
        <p> Reset Password</p>
      </div>
      <div className="login_form">
       
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              {...register("otp", {
                required: true,
                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                maxLength: 4,
                minLength: 4
              })}
              className="form-control"
              placeholder="Enter OTP"
              type="number"
            />
            {errors.otp?.type === "required" && (
              <p className="text-danger">OTP is required.</p>
            )}
            {errors.otp?.type === "pattern" && (
              <p className="text-danger">OTP must be numbers only.</p>
            )}
            {errors.otp?.type === "maxLength" && (
              <p className="text-danger">OTP must be 4 digits.</p>
            )}
            {errors.otp?.type === "minLength" && (
              <p className="text-danger">OTP must be 4 digits.</p>
            )}
          </div>
          
          <div className="form-group">
            <input
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                maxLength: 50,
                minLength: 8
              })}
              className="form-control"
              type="password"
              placeholder="Enter Password"
            />
            {errors.password?.type === "required" && (
              <p className="text-danger">You must specify a password.</p>
            )}
            {errors.password?.type === "pattern" && (
              <p className="text-danger">Password must have one small letter ,capital letter, digit and special character.</p>
            )}
            {errors.password?.type === "maxLength" && (
              <p className="text-danger">Password must have maximum length of 50 characters.</p>
            )}
            {errors.password?.type === "minLength" && (
              <p className="text-danger">Password must have at least 8 characters.</p>
            )}
          </div>
          <div className="form-group">
            <input
              {...register("password_repeat", {
                required: true,
                validate: value =>
                  value === password.current || "Password and confirm password does not match"
              })}
              className="form-control"
              type="password"
              placeholder="Enter Confirm Password "
            />
            {errors.password_repeat && (
              <p className="text-danger">{errors.password_repeat.message}</p>
            )}
          </div>
          <div className="otp">
            <p onClick={handleResetPwdResendOTP}>Resend OTP</p>
          </div>
          <div className="text-center mt-3">
            <button type="submit" className="as_btn" onClick={() => {
              // msgError.message = null;
              formData.errorResponse = null;
              // toggleShowA(true)
            }}>Submit</button>
          </div>
          {/* {msgError || formData.errorResponse && */}
          <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={4000} bg={msgError?.status || formData?.errorResponse?.status ? 'success' : 'danger'} className="otpfixed_toast">
            <Toast.Body>{msgError?.message ? msgError?.message : (formData.errorResponse && formData.errorResponse.message) ? formData.errorResponse.message : ""}</Toast.Body>
          </Toast>
          {/* } */}
        </form>
        <p className="text-center as_margin0 as_padderTop20">Back to <span onClick={handleFormType} className="as_orange as_signup backtologin">Login</span></p>
      </div>
      </>
    );
  }

  //........Change Password.........

  if (formType === "change_password") {
    return (
      <div className="login_form col-md-6 col-lg-6  mt-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group form_effect">
            <label>Old Password</label>
            <input
              {...register("oldPassword", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                maxLength: 50,
                minLength: 8
              })}
              className="form-control"
              type="password"
              placeholder="Enter Old Password"
            />
            {errors.oldPassword?.type === "required" && (
              <p className="text-danger">You must specify a password.</p>
            )}
            {errors.oldPassword?.type === "pattern" && (
              <p className="text-danger">Password must have one small letter ,capital letter, digit and special character.</p>
            )}
            {errors.oldPassword?.type === "maxLength" && (
              <p className="text-danger">Password must have maximum length of 50 characters.</p>
            )}
            {errors.oldPassword?.type === "minLength" && (
              <p className="text-danger">Password must have at least 8 characters.</p>
            )}
          </div>
          <div className="form-group form_effect">
            <label>New Password</label>
            <input
              {...register("newPassword", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                maxLength: 50,
                minLength: 8
              })}
              className="form-control"
              type={passwordShown ? "text" : "password"}
              placeholder="Enter New Password"
            />
            <span onClick={togglePasswordVisiblity} className="shownpwd">{passwordShown ? <EyeFill /> :  <EyeSlashFill />}</span>
            {errors.newPassword?.type === "required" && (
              <p className="text-danger">You must specify a password.</p>
            )}
            {errors.newPassword?.type === "pattern" && (
              <p className="text-danger">Password must have one small letter ,capital letter, digit and special character.</p>
            )}
            {errors.newPassword?.type === "maxLength" && (
              <p className="text-danger">Password must have maximum length of 50 characters.</p>
            )}
            {errors.newPassword?.type === "minLength" && (
              <p className="text-danger">Password must have at least 8 characters.</p>
            )}
          </div>
          <div className="form-group form_effect">
            <label>Confirm Password</label>
            <input
              {...register("password_repeat", {
                required: true,
                validate: value =>
                  value === newPassword.current || "Password and confirm password does not match"
              })}
              className="form-control"
              type="password"
              placeholder="Enter Confirm Password "
            />
            {errors.password_repeat?.type === "required" && (
              <p className="text-danger">Confirm Password is required.</p>
            )}
            {errors.password_repeat && (
              <p className="text-danger">{errors.password_repeat.message}</p>
            )}
          </div>
          <div className="mt-3">
          {/* {props?.loading?
            <button type="submit" className="as_btn ms-0" >Processing...</button>: */}
            <button type="submit" className="as_btn ms-0" onClick={() => {
              formData.passwordResponse = null;
              toggleShowA(true);
              props?.setLoading(true)
            }}> {props.errorMs ? "Processing..." : "Submit"}</button>
          </div>
          {formData?.passwordResponse && <Toast show={showA} onClose={() => toggleShowA(false)} autohide={true} delay={3000} bg={formData?.passwordResponse?.status ? 'success' : 'danger'} className="fixed_toast">
            <Toast.Body>{formData?.passwordResponse?.message ? formData?.passwordResponse?.message : ""}</Toast.Body>
          </Toast>}
        </form>
      </div>
    );
  }
}

export default CommonForm;