import React, { useEffect, useState, useRef } from "react";

const Timer = () => {
  const [timer, setTimer] = useState(0);
  const countRef = useRef(null);

  const formatTime = (timer) => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)
    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }

  const updateTime = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  useEffect(() => {
    let tstring = window.localStorage.getItem('astroCallStartsTime');
    if (tstring && timer === 0) {
      let ts = Number(tstring);
      let d1 = new Date(ts);
      let currentDate = new Date();
      let duration = Math.abs(currentDate.getTime() - d1.getTime()) / 1000;
      setTimer(Math.floor(duration))
    }
  }, [])

  useEffect(() => {
    updateTime()
    return () => {
      clearInterval(countRef.current);
    };
  }, [])

  return (<p>{formatTime(timer)}</p>);
}

export default Timer;