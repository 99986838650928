import { useEffect, useState,useContext } from 'react'
import ApiServices from '../../utils/ApiServices'
import moment from 'moment'
import {
  CardImage,
  PencilSquare,
  HeartFill,
  Chat,
  CursorFill,
  Heart,
  Trash
} from 'react-bootstrap-icons';
import Switch from "../ToggleButton";
import { useNavigate } from "react-router-dom";
import { Modal, Dropdown,Button } from 'react-bootstrap';
import { UserContext } from '../../context/UserProvider'
import { getUserToken } from "../../utils/helper";
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';

const QuestFeeds = (props) => {
  let { userInfo} = props;
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const { logOut } = useContext(UserContext)
  const [getFeeds, setGetFeeds] = useState()
  const [feeds, setFeeds] = useState(false)
  const [showRModal, setShowrmodal] = useState(false)
  const [showPModal, setShowpmodal] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [uploadFile, setUploadFile] = useState('')
  const [postDesc, setPostDesc] = useState('')
  const [reasonList, setReasonList] = useState([]);
  const [state, setState] = useState({ reasonValue: '', reasonId: '' });
  const [postinfo, setPostinfo] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [perPage, setPerpage] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [showdt, setShowDt] = useState(false)
  const[dtkey,setDtkey]=useState()
  const[loading,setLoading]=useState(false)

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }


  const fetchData = async (event) => {
    let query = feeds ? 'Answered' : 'Pending';
    const res = await ApiServices.get(`astrologer/quest/list/${pageOffset + 1}/${perPage}/${query}`, {
      Authorization: getUserToken()
    })
    if (res.status == 200) {
      setGetFeeds(res?.data?.list);
      setPageCount(Math.ceil(res?.data?.total_count / perPage));
      props.setCount(res?.data?.total_count)
      props.setHdtype(feeds)
    } else {
      console.log('res', res)
      if(res.response.data.status==4){
        logOut()
      }
    }
  }

  useEffect(() => {
    setPostDesc('');
    setUploadFile('');
    fetchData();
    setShowrmodal(false);
    setPostinfo('');
  }, [refetch])

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
    setRefetch(!refetch)
    window.scrollTo(0,0)
  };

  const handleStatus = () => {
    setPageOffset(0)
    setFeeds(!feeds);
    setRefetch(!refetch)
}

  const renderPostImages = data => {
      return (
        <>
          <img
            src={ data?.userId[0]?.profileImage || './images/chat_UserImage.jpg'}
            className='img-fluid user_img'
          />
          <div className='ms-2'>
            <h4>{data?.userId[0]?.name}</h4>
            <p>{moment(data?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
          </div>
        </>
      )
  }

  const navigateToFeeds = (id) => {
    navigate(`/questfeed/${id}`, { replace: false });
  };

  const handleDeletePost = async (data) => {
    let payload = {
      "postId": data?._id
    }
    let res = await ApiServices.delete("/astrologer/post/delete", payload, {
      Authorization: getUserToken()
    })
    if (res.status == 200) {
      setShowDt(false)
      setRefetch(!refetch)
      addToast(res?.data?.message, { appearance: 'success' });
    }
    else {
      addToast(res?.data?.message, { appearance: 'error' });
    }
  }

  const handleShowPerPage = (number) => {
    setPageOffset(0);
    setPerpage(number);
    setRefetch(!refetch);
    window.scrollTo(0,0);
  }

  const renderShowPerPage = () => {
    let numbers = [10, 20, 50, 100];
    return (
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          Show: {perPage}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {numbers && numbers.map(number => {
            return <Dropdown.Item onClick={() => handleShowPerPage(number)} className={`${perPage === number ? 'active' : null}`}>{number}</Dropdown.Item>;
          })}
        </Dropdown.Menu>
      </Dropdown>)
  }

  return (
    <>
      <div className='astrologer_feed_inner mt-3 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-lg-9'>
              <div className='astro_feed_sec'>
                <div>
                  {getFeeds && getFeeds?.length > 0 ? (
                    <div>
                      {getFeeds.map(data => (
                        <div className='card _feed_box mt-4' key={data?._id}>
                          <div className='card-header d-flex align-items-center justify-content-between'>
                            <div className='user_info d-flex align-items-center'>
                              {renderPostImages(data)}
                            </div>
                            <div className="fw-medium "> 
                            <span className="text-capitalize me-2">{data?.utmSource}</span>
                            <span>
                             {data?.currency === "INR" ? `₹${data?.amount}`:`$${data?.amount}`}
                             </span>
                           </div>
                          </div>
                          <div className='card-body'>
                          <div className="detail-card">
                        <ul className="nav question-item">
                        <li className="me-4">
                         {data?.additionalDetails?.name}
                        </li>
                        <li className="me-4">
                         Date of birth : {moment.utc( data?.additionalDetails?.dob).format('ll')}
                        </li>
                        <li className="me-4">
                        Birth Time : { moment.utc({hour: data?.additionalDetails?.hour, minute: data?.additionalDetails?.min}).format('hh:mm A')}
                       
                        </li>
                        <li className="me-4 text-capitalize ">
                        Gender : {data?.additionalDetails?.gender}
                        </li>
                       <li className="">
                        Address : {data?.additionalDetails?.address}
                        </li>
                       
                      </ul>
                      </div>
                            <p className="text-danger">Question :</p>
                            <p>{data?.description}</p>
                          </div>
                          <div className='card-footer'>
                            <ul className='nav'>
                             
                              <li className={`cursor-pointer ${data?.status === 'Pending'?'text-danger':'text-success'}`}>
                                <span onClick={() => navigateToFeeds(data?._id)} >
                                  {/* <Chat />{' '} */}
                                   {data?.status === 'Pending' ? 'Write Answer ->' :'View Answer ->'}
                                </span>
                              </li>
                              <li className="cursor-pointer">
                                {/* <a href='#' >
                                  <CursorFill />
                                  Share
                                </a> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                      <div className='call_history_box my-5'>
                        <p>No data available</p>
                      </div>
                    )}
                </div>
                {getFeeds && getFeeds?.length > 0 ? 
                <div className="mt-2 d-flex align-items-center justify-content-between" id="feeds-pagination">
                  {renderShowPerPage()}
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                  />
                </div>:""}
              </div>
            </div>
            <div className='col-md-4 col-lg-3'>
              <div className='feeds_requset_sec mt-4'>
              <div className='wall_box d-flex align-items-center justify-content-between cursor-pointer'>
                  
                  <h5 htmlFor='mywall-switch' onClick={handleStatus}>Answered</h5>
                  <Switch
                    isOn={feeds}
                    onColor="#347A16"
                    id='mywall-switch'
                    handleToggle={() => handleStatus()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
      backdrop="static" onHide={handleCloseDt} 
      >
      <Modal.Header className="modal-header-hd">
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this post?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="as_btn log_btn" onClick={handleCloseDt}>
          Cancel
        </Button>
        <Button variant="primary" className="as_btn log_btn" onClick={()=>{handleDeletePost(dtkey); }}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>

    </>
  )
}
export default QuestFeeds
