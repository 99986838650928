import React, { useState, useEffect, useContext } from "react";
import { MicMuteFill, TelephoneFill } from "react-bootstrap-icons";
import useAgora from "./Hook";
import { UserContext } from "../../context/UserProvider";

const Controls = (props) => {
  const [useClient] = useAgora();
  const { endCall } = useContext(UserContext)
  const client = useClient();
  const { track, setStart, handelLeaveCall } = props;
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = async (val) => {
    setIsMuted(!isMuted);
    await track.setEnabled(val)
  };

  const leaveChannel = async () => {
    window.localStorage.removeItem('astroCallStartsTime');
    await client.leave();
    client.removeAllListeners();
    setStart(false);
    handelLeaveCall();
    window.location.reload(false);
  };

  const endCallFunction = async () => {
    window.localStorage.removeItem('astroCallStartsTime');
    await client.leave();
    client.removeAllListeners();
    handelLeaveCall();
    window.location.reload(false);
  }

  useEffect(() => {
    if (endCall) { endCallFunction() }
  }, [endCall])


  return (
    <div className="d-flex justify-content-center mt-3 controls">
      <button className={!isMuted ? "on" : ""} className="as_btn" onClick={() => toggleMute(!isMuted)} title={`${isMuted ? 'Mute' : 'Unmute'} audio`}>
        {isMuted ? <MicMuteFill size="20px" /> : <MicMuteFill color="red" size="20px" />}
      </button>
      {<button className="as_btn" onClick={() => leaveChannel()} title="Leave this call"><TelephoneFill color="red" size="20px" /></button>}
    </div>
  );
};

export default Controls;
