import React, { useState, useEffect } from "react";
import VideoCall from "./VideoCall";

const App = ({ callingData, handelLeaveCall }) => {
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [appID, setAppID] = useState("");
  const [userID, setUserID] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [token, setToken] = useState("");
  useEffect(() => {
    if (callingData.appId) {
      setChannelName(callingData.channelName)
      setAppID(callingData.appId)
      setToken(callingData.callId)
      setUserID(callingData.userId)
      setUserProfile(callingData.profileImage)
    }
  }, [callingData]);
  return (
    <div>
      {inCall ? (
        <VideoCall
          channelName={channelName}
          appID={appID} token={token}
          userProfile={userProfile}
          handelLeaveCall={handelLeaveCall}
        />
      ) : (
          <img src="/images/calling.gif" alt="loading..." width="200" height="200" onClick={() => setInCall(true)} title="Accept this call"/>
        )}
    </div>
  );
};

export default App;
