import React from "react";
import {Image} from "react-bootstrap-icons";

const ChatImage = props => {
  const handleImage = event => {
    let newfile = event.target.files[0];
    let mimetype = newfile.type ? newfile.type : "";
    if (mimetype === "image/jpeg" || mimetype === "image/png") {
      let reader = new FileReader();
      reader.readAsDataURL(newfile);
      reader.onload = function () {
        let sendObj = {
          filename: newfile.name,
          mimetype,
          data: reader.result,
        };
        props.handleImage(sendObj, newfile);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  return (
    <label htmlFor="image-upload-chat">
      <input type="file" id="image-upload-chat" onChange={handleImage} className="msger-input bg-transparent border as_hideoutline d-none" />
      {props.preview ? <img src={URL.createObjectURL(props.preview)} alt="img-chat-input" width={50} height={50} /> : <Image width={50} height={50} />}
    </label>
  );
};

export default ChatImage;
