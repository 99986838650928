import Cookies from 'universal-cookie';
import { enc } from "crypto-js";
import AES from "crypto-js/aes";
import { io } from 'socket.io-client';

const cookies = new Cookies();
// Set cookies after login and sign up
export const setCookie = (cookieName, data) => {
  let expire = { maxAge: 60 * 60 * 2, secure: true, sameSite: true };
  let encryptedData = encryptDataStr(JSON.stringify(data));
  cookies.set(cookieName, encryptedData, expire);
};

// Check cookies existance and get it
export const getCookeiDetail = (cookieName) => {
  var user = cookies.get(cookieName) || false;
  let decrypteData = decryptDataStr(user);
  if (decrypteData) {
    return JSON.parse(decrypteData);
  } else {
    return null;
  }
};

// Check user authenticated
export const isAuthenticated = () => {
  var user = cookies.get("astrologer_data") || false;
  if (user) {
    return true;
  } else {
    return false;
  }
};

// Delete cookies onclick logout menu
export const deleteCookie = (cookieName) => {
  cookies.remove(cookieName);
};

// Encrypt data
export const encryptDataStr = (str) => {
  let ciphertext = AES.encrypt(str, process.env.REACT_APP_ENCRYPT_KEY);
  return encodeURIComponent(ciphertext.toString());
};

// Decrypt data
export const decryptDataStr = (str) => {
  let decodedStr = decodeURIComponent(str);
  return AES.decrypt(decodedStr, process.env.REACT_APP_ENCRYPT_KEY).toString(
    enc.Utf8
  );
};

// Get user token by cookies
export const getUserToken = () => {
  var data = cookies.get("astrologer_data");
  let decrypteData = decryptDataStr(data);
  if (decrypteData) {
    let data1 = JSON.parse(decrypteData);
    let token = `bearer ${data1.accessToken}`;
    return token;
  } else {
    return null;
  }
};

// replace data key for react select
export const replaceDataKey = (data, type = "create_option") => {
  let updatedObj = []
  if (data && data.length > 0) {
    if (type === "create_form") {
      data.forEach((item, i) => {
        updatedObj.push(item.value)
      });
    } else {
      data.forEach((item, i) => {
        updatedObj.push({ label: item.name, value: item._id })
      });
    }
  }
  return updatedObj;

}

// get Socket Connection
export const getSocketConnection = () => {
  const socket = io(process.env.REACT_SOCKET_APP_API_URL);
  return socket;
};