import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {UserProvider} from "./context/UserProvider";
import AppRouter from "./components/Router";
import {ToastProvider, DefaultToastContainer} from "react-toast-notifications";
import {SocketProvider} from "./context/socketProvider";

function App() {
  const ToastContainer = props => <DefaultToastContainer className="toast-container" style={{zIndex: 10000, top: 80}} {...props} />;

  return (
    <UserProvider>
      <ToastProvider autoDismiss={true} autoDismissTimeout={3000} components={{ToastContainer}}>
        <SocketProvider>
          <AppRouter />
        </SocketProvider>
      </ToastProvider>
    </UserProvider>
  );
}

export default App;
