import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = ({ imgSrc, setCroppedImage, aspect = 3 / 5, keepSelection = true }) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [imgUrl, setImgUrl] = useState();

  // Create blob on every cropped movement
  useEffect(() => {
    const t = setTimeout(async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef?.current && previewCanvasRef?.current) {
        let url = await imgBlob(imgRef?.current, completedCrop);
        setImgUrl(url);
        setCroppedImage(url);
      }
    }, 100);
    return () => {
      clearTimeout(t);
    };
  }, [completedCrop]);

  // Used to select crop in center
  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  // Select cropped image to center on image load
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  // Create canvas to blob with promise
  const toBlob = (canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  };

  // Create blob for cropped image
  const imgBlob = async (image, crop) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
    ctx.restore();

    const blob = await toBlob(canvas);
    return blob;
  };

  return (
    <div className="App">
      {imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          keepSelection={keepSelection}
        >
          <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      <div style={{ display: "none" }}>{completedCrop && <img ref={previewCanvasRef} alt="Crop me" src={imgUrl} />}</div>
    </div>
  );
};

export default ImageCrop;
