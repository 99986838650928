import React from 'react'
import { useEffect, useState, useContext } from 'react'
import Header from '../components/Header'
import CommonForm from '../components/Form'
import { Tab, Nav, Modal, Button } from 'react-bootstrap'
import ApiServices from '../utils/ApiServices'
import { getCookeiDetail } from '../utils/helper'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { UserContext } from '../context/UserProvider'
import { replaceDataKey, getUserToken } from '../utils/helper'
import Toast from 'react-bootstrap/Toast'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment'
import ImageUploader from '../components/Multi-ImageUpload'
import Errortoast from '../components/ErrorToast'

import {
  PencilSquare,
  PencilFill,
  X,
  Check2,
  Calendar2Check,
  BoxArrowInRight,
  PersonFill,
  ShieldLock,
  Images
} from 'react-bootstrap-icons'
import ImageCrop from '../components/ImageCrop'

const Profile = () => {
  const timeData = [
    {
      day: 'sunday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'monday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'tuesday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'wednesday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'thursday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'friday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    },

    {
      day: 'saturday',
      time: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    }
  ]

  const [timeAvailability, setTimeAvailability] = useState(timeData)
  const { logOut } = useContext(UserContext)
  const [astrologerDetail, setAstrologerDetail] = useState()
  const [languageList, setLanguageList] = useState([])
  const [specialitylist, setSpecialitylist] = useState([])
  const [productDetails, setProductDetails] = useState([])
  const [profile, setProfile] = useState('')
  const [uploadFile, setUploadFile] = useState('')
  const [errorResponse, setErrorResponse] = useState()
  const [passwordResponse, setPasswordResponse] = useState()
  const [showA, setShowA] = useState(false)
  const [updatingProfile, setUpdatingProfile] = useState(false)
  const [showModal, setShowmodal] = useState(false)
  const [croppedImg, setCroppedImg] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [error, setError] = useState(false)
  const [errorMs, setErrorMs] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showdt, setShowDt] = useState(false)
  const [dtkey, setDtkey] = useState()
  const [imagecount, setImageCount] = useState()
  const { addToast } = useToasts();
  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData('astrologer_detail')
  }, [updatingProfile])

  const toggleShowA = () => setShowA(!showA)
  const handleModalClose = () => setShowmodal(false)
  const handleModalShow = () => setShowmodal(true)

  function generateTime(value, day, time) {
    const copyData = [...timeAvailability]
    const reqIndex = copyData.findIndex(x => x.day === day)
    const reqDay = { ...copyData[reqIndex] }
    reqDay.time[0][time] = value
    copyData[reqIndex] = reqDay
    setTimeAvailability([...copyData])
  }

  function timeHelper(day, time) {
    const reqIndex = timeAvailability.findIndex(x => x.day === day)
    let copyTime = timeAvailability[reqIndex].time[0][time]
    if (copyTime == '' || copyTime == null) {
      return null
    }
    copyTime = new Date(copyTime)
    return copyTime
  }

  const isEnddateEnabled = day => {
    const reqIndex = timeAvailability.findIndex(x => x.day === day)
    const startTime = timeAvailability[reqIndex].time[0].startTime
    if (startTime && startTime !== '') {
      return true
    }
    return false
  }

  useEffect(() => {
    fetchData('astrologer_detail')
    fetchData('language_list')
    fetchData('speciality_list')
    fetchData('productDetail')
  }, [])

  const updateProfile = async (payload, type = 'json') => {
    setErrorMs(true)
    setUpdatingProfile(true)
    if (type === 'form') {
      let formData = new FormData()
      formData.append('profileImage', payload, `${astrologerDetail?.name}.jpeg`)
      payload = formData
    }

    const response = await ApiServices.put(
      `astrologer/updateAstrologer`,
      payload,
      {
        'content-type':
          type === 'form' ? 'multipart/form-data' : 'application/json',
        Authorization: getUserToken()
      }
    )

    if (response?.status == 200 && response?.data?.status == true) {
      setLoading(false)
      setErrorMs(false)
      setErrorResponse({
        status: response?.data?.status,
        message: response?.data?.message
      })
      if (type === 'form') {
        setAstrologerDetail({ ...astrologerDetail, profileImage: profile })
        setProfile('')
        handleModalClose()
      }
    } else {
      setErrorMs(false)
      // console.log('UpdateDetailsError:', response)
      if (response.data?.status == 4) {
        logOut()
      }
      setErrorResponse({
        status: response.data.status,
        message: response.data.message
      })
    }
    setUpdatingProfile(false)
  }

  const fetchData = async (type = '') => {
    if (type === 'astrologer_detail') {
      const data = getCookeiDetail('astrologer_data')
      const res = await ApiServices.get(
        `astrologer/astrologerDetails/${data?._id}`
      )
      if (res?.status == 200 && res?.data?.status) {
        const profileData = res?.data?.data[0]
        setAstrologerDetail(profileData)
        setImageCount(profileData?.galleryImages.length)
        if (profileData && profileData?.timeSchedule.length > 0) {
          setTimeAvailability(profileData?.timeSchedule)
        }
      } else {
        console.log('ProfileError:', res)
      }
    }

    if (type === 'language_list') {
      const res = await ApiServices.get(`astrologer/languageList`)
      if (res?.status == 200 && res?.data?.status) {
        const languageList = replaceDataKey(res?.data?.data)
        setLanguageList(languageList)
      } else {
        console.log('Language:', res)
      }
    }

    if (type === 'speciality_list') {
      const res = await ApiServices.get(`astrologer/specialtyList`)
      if (res.status == 200 && res.data.status) {
        const specialityList = replaceDataKey(res?.data?.data)
        setSpecialitylist(specialityList)
      } else {
        console.log('Speciality ERR:', res)
      }
    }
    if (type === 'productDetail') {
      const res = await ApiServices.get('astrologer/productTypeList')
      if (res?.status == 200 && res?.data?.status) {
        const productData = res?.data?.data
        setProductDetails(productData)
      } else {
        console.log('ProductError:', res)
      }
    }
  }

  const submitAvailability = async () => {
    setErrorResponse(null)
    const payload = { timeSchedule: timeAvailability }
    const response = await ApiServices.put(
      `astrologer/updateAstrologer`,
      payload,
      {
        'content-type': 'application/json',
        Authorization: getUserToken()
      }
    )
    if (response?.status == 200 && response?.data?.status == true) {
      setErrorResponse({
        status: response?.data?.status,
        message: 'Time Updated Successfully.'
      })
    } else {
      // console.log('UpdateDetailsError:', response)
      if (response.data.status == 4) {
        logOut()
      }
      setErrorResponse({
        status: response?.data?.status,
        message: response?.data?.message
      })
    }
    toggleShowA()
  }

  const uploadImage = e => {
    if (e.target.files.length !== 0) {
      handleModalShow()
      setProfile(URL.createObjectURL(e?.target?.files[0]))
      setUploadFile(e?.target?.files[0])
    }
  }

  const changePassword = async payload => {
    setErrorMs(true)
    setPasswordResponse(null)
    const res = await ApiServices.post('astrologer/changePassword', payload, {
      'content-type': 'application/json',
      Authorization: getUserToken()
    })
    if (res?.status == 200 && res?.data?.status == true) {
      setLoading(false)
      setErrorMs(false)
      setPasswordResponse({
        status: res?.data?.status,
        message: res?.data?.message
      })
    } else {
      setErrorMs(false)
      // console.log('Change Password Error:', res)
      if (res.data?.status == 4) {
        logOut()
      }
      setPasswordResponse({
        status: res?.data?.status,
        message: res?.data?.message
      })
    }
  }

  const UploadGallery = async images => {
    setIsLoading(true)
    let formData = new FormData()

    if (imagecount + images.length <= 5) {
      images.forEach(element => {
        if (element.file) formData.append('galleryImages[]', element.file)
      })
      const response = await ApiServices.put(
        `astrologer/updateAstrologerGallaryImg`,
        formData,
        {
          'content-type': 'multipart/form-data',
          Authorization: getUserToken()
        }
      )
      if (response?.status == 200 && response?.data?.status == true) {
        fetchData('astrologer_detail')
      } else {
        setErrorMessage(response?.data?.message)
        setError(true)
        // console.log('ERROR---', response)
        if(response.data.status==4){
          logOut()
        }
      }
    } else {
      addToast("Only 5 images save in your gallery", { appearance: 'error' });
    }
    setIsLoading(false)
  }

  const handleDeleteGallery = async key => {
    let filename = key
    const res = await ApiServices.post(
      `astrologer/deleteAstrologerFiles/gallery`, { filename: filename },
      {
        'content-type': 'application/json',
        Authorization: getUserToken()
      }
    )
    if (res?.status == 200 && res?.data?.status == true) {
      setShowDt(false)
      fetchData('astrologer_detail')
      addToast("Delete image successfully", { appearance: 'success' });
    } else {
      // console.log('ERROR---', res)
      if(res.data.status==4){
        logOut()
      }
    }
  }

  return (
    <>
      <Header />
      <section className='astrologer_area profile_setting_Area'>
        <div className='astrologer_heading mb-lg-5 mb-md-5 mb-2'>
          <div className='container'>
            <h1>Profile</h1>
          </div>
        </div>
        <div className='container'>
          <Tab.Container defaultActiveKey='profile'>
            <div className='profile_setting_inner'>
              <div className='row'>
                <div className='col-md-12 col-lg-4 col-xl-3'>
                  <div className='profile_tab_box'>
                    <div className='profile_info'>
                      <div className='edit_profile_image position-relative'>
                        <div className='avatar-edit'>
                          <div className='d-flex justify-content-center'>
                            <input
                              name='profileImage'
                              type='file'
                              id='imageUpload'
                              accept='.png, .jpg, .jpeg'
                              onChange={uploadImage}
                              className='d-none'
                            />
                            <label htmlFor='imageUpload' className='edit_image'>
                              <PencilFill />
                            </label>
                          </div>
                          <img
                            src={astrologerDetail?.profileImage}
                            className='imgProfile'
                          />
                        </div>
                      </div>
                      <h4 className='text-capitalize'>
                        {astrologerDetail?.name}
                      </h4>
                    </div>

                    <div className='profile_tab'>
                      <Nav>
                        <ul className='tabs-left nav nav-tabs '>
                          <li>
                            <Nav.Item>
                              <Nav.Link eventKey='profile' className='link_url'>
                                <span>
                                  <PersonFill />
                                </span>
                                Profile
                              </Nav.Link>
                            </Nav.Item>
                          </li>
                          <li>
                            <Nav.Item>
                              <Nav.Link eventKey='edit' className='link_url'>
                                <span>
                                  <PencilSquare />
                                </span>
                                Edit Profile
                              </Nav.Link>
                            </Nav.Item>
                          </li>
                          <li>
                            <Nav.Item>
                              <Nav.Link eventKey='gallery' className='link_url'>
                                <span>
                                  <Images />
                                </span>
                                Gallery Images
                              </Nav.Link>
                            </Nav.Item>
                          </li>
                          {/* <li>
                            <Nav.Item>
                              <Nav.Link
                                eventKey='availability'
                                className='link_url'
                              >
                                <span>
                                  <Calendar2Check />
                                </span>
                                Availability
                              </Nav.Link>
                            </Nav.Item>
                          </li> */}
                          <li>
                            <Nav.Item>
                              <Nav.Link eventKey='change' className='link_url'>
                                <span>
                                  <ShieldLock />
                                </span>
                                Change Password
                              </Nav.Link>
                            </Nav.Item>
                          </li>
                          <li>
                            <Nav.Item>
                              <Nav.Link
                                type='button'
                                eventKey='logout'
                                onClick={logOut}
                                className='link_url'
                              >
                                <span>
                                  <BoxArrowInRight />
                                </span>
                                Logout
                              </Nav.Link>
                            </Nav.Item>
                          </li>
                        </ul>
                      </Nav>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-8 col-xl-9'>
                  <Tab.Content>
                    <Tab.Pane eventKey='profile'>
                      <div className='profile_content'>
                        <div className='tab-content'>
                          <div className='tab-pane active' id='tab1'>
                            <div className='profile_box'>
                              <div className='profile_inner_text'>
                                {astrologerDetail &&
                                  Object.keys(astrologerDetail).length > 0 && (
                                    <ul>
                                      <li>
                                        <span className='first'>Full Name</span>
                                        <span className='last text-capitalize'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.name}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>Email ID</span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.email}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>
                                          Date of Birth
                                        </span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.DOB
                                            ? moment(
                                              astrologerDetail?.DOB
                                            ).format('DD/MM/YYYY')
                                            : ''}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>Address</span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.address}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>
                                          Mobile Number
                                        </span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.contactNumber}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>Language</span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.languages &&
                                            astrologerDetail.languages.length >
                                            0 &&
                                            astrologerDetail.languages.map(
                                              (lang, i) => (
                                                <span
                                                  className='text-capitalize'
                                                  key={i}
                                                >
                                                  {i === 0
                                                    ? lang.name
                                                    : `, ${lang.name}`}
                                                </span>
                                              )
                                            )}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>
                                          Speciality
                                        </span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.specialty &&
                                            astrologerDetail.specialty.length >
                                            0 &&
                                            astrologerDetail.specialty.map(
                                              (spec, i) => (
                                                <span
                                                  className='text-capitalize'
                                                  key={i}
                                                >
                                                  {i === 0
                                                    ? spec.name
                                                    : `, ${spec.name}`}
                                                </span>
                                              )
                                            )}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>
                                          Experience
                                        </span>
                                        <span className='last'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.experience}
                                        </span>
                                      </li>
                                      <li>
                                        <span className='first'>About Me</span>
                                        <span className='last text-capitalize'>
                                          <span>:</span>{' '}
                                          {astrologerDetail?.aboutMe}
                                        </span>
                                      </li>
                                    </ul>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='edit'>
                      <div className='tab-pane' id='tab2'>
                        {astrologerDetail?.languages !== undefined && (
                          <CommonForm
                            formType='Edit_profile_page'
                            submitForm={updateProfile}
                            setLoading={setLoading}
                            loading={loading}
                            errorMs={errorMs}
                            formData={{
                              astrologerDetail,
                              defaultLanguage: replaceDataKey(
                                astrologerDetail?.languages
                              ),
                              defaultSpeciality: replaceDataKey(
                                astrologerDetail?.specialty
                              ),
                              languageList,
                              specialitylist,
                              productDetails,
                              errorResponse
                            }}
                          />
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='gallery'>
                      <div className='tab-pane pro_gallery_sec' id='tab3'>
                        {isLoading && (
                          <div className='preloaderOverlay'>
                            <div className='overlayLoader'>
                              <div
                                className='spinner-border'
                                role='status'
                              ></div>
                            </div>
                          </div>
                        )}
                        <div>
                          <div>
                            <h6>Gallery Images</h6>
                          </div>
                          {error === true ? (
                            <Errortoast message={errorMessage} />
                          ) : (
                            ''
                          )}
                          <div>
                            <div className='scroll'>
                              {astrologerDetail?.galleryImages &&
                                astrologerDetail?.galleryImages.length > 0 ? (
                                <ul className='nav'>
                                  {astrologerDetail?.galleryImages.map(img => (
                                    <li>
                                      {<img src={img.url} />}
                                      <button
                                        type='submit'
                                        onClick={() => { handleShowDt(); setDtkey(img.key) }
                                        }
                                      >
                                        {/*handleDeleteGallery(img.key)*/}
                                        Delete
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div className='call_history_box my-5'>
                                  <p>Images not Available</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        {astrologerDetail?.galleryImages.length <= 10 ? <ImageUploader submitGallery={UploadGallery} /> : <div style={{ color: "red", display: 'flex', justifyContent: "center" }}>You can not upload more than 10 images *</div>}
                        {/* <ImageUploader submitGallery={UploadGallery} />*/}
                      </div>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey='availability'>
                      <div className='tab-pane' id='tab4'>
                        <div className='p-2 m-2'>
                          <div className='row border-bottom'>
                            <div className='col-4'>Day</div>
                            <div className='col-4'>Start Time</div>
                            <div className='col-4'>End Time</div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Sunday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('sunday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'sunday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('sunday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'sunday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('sunday')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Monday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('monday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'monday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('monday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'monday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('monday')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Tuesday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('tuesday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'tuesday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('tuesday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'tuesday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('tuesday')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Wednesday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper(
                                    'wednesday',
                                    'startTime'
                                  )}
                                  onChange={e =>
                                    generateTime(e, 'wednesday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('wednesday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'wednesday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('wednesday')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Thursday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('thursday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'thursday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('thursday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'thursday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('thursday')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mt-2'>
                            <div className='col-4'>Friday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('friday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'friday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('friday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'friday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('friday')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row mt-2'>
                            <div className='col-4'>Saturday</div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('saturday', 'startTime')}
                                  onChange={e =>
                                    generateTime(e, 'saturday', 'startTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='as_input_feild availinput'>
                                <DatePicker
                                  selected={timeHelper('saturday', 'endTime')}
                                  onChange={e =>
                                    generateTime(e, 'saturday', 'endTime')
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  placeholderText='Enter Time'
                                  disabled={!isEnddateEnabled('saturday')}
                                />
                              </div>
                            </div>
                            <button
                              type='button'
                              onClick={submitAvailability}
                              className='as_btn log_btn mt-3'
                            >
                              Submit
                            </button>
                            <Toast
                              show={showA}
                              onClose={toggleShowA}
                              autohide={true}
                              delay={3000}
                              bg={errorResponse?.status ? 'success' : 'danger'}
                              className='fixed_toast'
                            >
                              <Toast.Body>
                                {errorResponse?.message
                                  ? errorResponse?.message
                                  : ''}
                              </Toast.Body>
                            </Toast>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane> */}
                    <Tab.Pane eventKey='change'>
                      <div className='tab-pane px-3' id='tab5'>
                        <div className='mt-4'>
                          <h4 className='mb-0'>Change Password</h4>
                        </div>
                        <CommonForm
                          formType='change_password'
                          setLoading={setLoading}
                          loading={loading}
                          errorMs={errorMs}
                          submitForm={changePassword}
                          formData={{ passwordResponse }}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </Tab.Container>
        </div>
        <Modal show={showModal} size="md">
          <Modal.Body>
            <ImageCrop
              imgSrc={profile}
              setCroppedImage={src => setCroppedImg(src)}
            />
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleModalClose} className='as_btn log_btn'>
              Close
            </button>
            <button
              onClick={() => updateProfile(croppedImg, 'form')}
              className='as_btn log_btn'
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
          backdrop="static" onHide={handleCloseDt}
        >
          <Modal.Header className="modal-header-hd">
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this image?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="as_btn log_btn" onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant="primary" className="as_btn log_btn" onClick={() => { handleDeleteGallery(dtkey); }}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
}
export default Profile
