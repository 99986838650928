import React from "react";
import { useState } from "react";
import CommonForm from "../components/Form";
import ApiServices from "../utils/ApiServices";
import { useContext } from "react";
import { UserContext } from "../context/UserProvider";


const Login = () => {
  const [errorResponse, setErrorResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState();
  const [showForm, setShowForm] = useState("Login");
  const [email, setEmail] = useState();
  const { login } = useContext(UserContext)

  const LoginForm = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
      username: data.username.toLowerCase().trim()
    }
    setErrorResponse(null);
    setEmail(payload.username);
    
    const res = await ApiServices.post("astrologer/astrologerLogin", payload)

    if (res.status == 200 && res.data.status == true) {
      const userData = res.data.data[0]
      login(userData)
      setErrorResponse({ status: res.data.status, message: res.data.message });
    }
    else {
      // console.log("LoginError:", res);
      if (res.data.verificationStatus === false) {
        setShowForm("otp");
        setVerifyMsg("You are not verified, Please verify your Email id");
        setTimeout(() => {
          setVerifyMsg("")
        }, 2000)
        setErrorResponse(null);
        const res = await ApiServices.post("astrologer/resendOtp", { email: payload.username })
        if (res.status == 200 && res.data.status == true) {
          setErrorResponse({ status: res.data.status, message: res.data.message });
        } else {
          setErrorResponse({ status: res.data.status, message: res.data.message });
          // console.log("OTP Error:", res);
        }
      }
      else {
        setErrorResponse({ status: res.data.status, message: res.data.message });
      }
    }
    setIsLoading(false);
  }

  const OTPForm = async (formData) => {
    setIsLoading(true);
    let payload = {
      email: email,
      otp: formData.otp
    }
    const response = await ApiServices.post("astrologer/otpVerify", payload)
    setErrorResponse(null);
    if (response.status == 200 && response.data.status == true) {
      setErrorResponse({ status: response.data.status, message: response.data.message });
      setShowForm('Login')
    } else {
      setErrorResponse({ status: response.data.status, message: response.data.message });
      // console.log("OTP Error:", response.data.message);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="as_main_wrapper">
        <section className="as_login_area">
          <img src="./images/login-shape.png" className="login-shape" />
          <div className="container">
            <div className="login_inner">
              <div className="login_logo">
                <img src="./images/logo_withtagline.png" />
              </div>
              {showForm === "Login" ? <CommonForm
                formType="Login_Form"
                submitForm={LoginForm}
                formData={{ errorResponse, isLoading }}
              />
                : <CommonForm
                  formType="Otp_form"
                  submitForm={OTPForm}
                  formData={{ errorResponse, email, isLoading }}
                  verifymsg={verifyMsg}
                />
              }
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Login;