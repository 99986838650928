import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import Switch from "../components/ToggleButton";
import { getUserToken } from "../utils/helper";
import { getCookeiDetail } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import Feeds from "../components/Feeds";


const Home = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [user, setUser] = useState();
  const data = getCookeiDetail("astrologer_data")
  const fetchData = async () => {
    const res = await ApiServices.get(`astrologer/astrologerDetails/${data?._id}`)
    if (res?.status == 200 && res?.data?.status == true) {
      const Data = res?.data?.data[0];
      setUser(Data);      
      if (Data?.availability == 2) {
        setIsAvailable(false)
      }
      else {
        setIsAvailable(true)
      }

    }
    else {
      console.log("Error:", res);
    }
  }

  const availabilityHandle = async () => {
    let payload = {};
    if (isAvailable) {
      payload.availability = 2
    }
    else {
      payload.availability = 1
    }
    const resp = await ApiServices.put(
      "astrologer/updateAvailability",
      payload,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )
    if (resp.status == 200 && resp.data.status == true) {
      setIsAvailable(!isAvailable)
    }
    else {
      console.log("ERROR", resp);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper">
        <div className="astrologer_heading">
          <div className="container d-flex justify-content-between">
            <h4>Welcome!<span className="fw-bold limitcar text-capitalize">{user?.name}</span> </h4>
            <div className="d-flex align-items-center flex-column flex-lg-row flex-md-row">
              <Switch
                isOn={isAvailable}
                onColor="#347A16"
                handleToggle={() => availabilityHandle()}
                id={'header-switch'}
              />
              {isAvailable ? <p style={{ color: "green", margin: "5px 10px", lineHeight: '1rem', visibility: isAvailable ? "visible" : "hidden" }}> You're Live</p> : <p style={{ color: "red", margin: "5px 10px", lineHeight: '1rem', visibility: !isAvailable ? "visible" : "hidden" }}> You're Offline</p>}
            </div>
          </div>
        </div>
        <div>
          <Feeds userInfo={user}/>
        </div>

      </section>
    </>
  );
};

export default Home;
