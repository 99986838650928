import { useEffect,useContext, useState } from "react";
import Header from "../components/Header";
import ApiServices from "../utils/ApiServices";
import { getUserToken } from "../utils/helper";
import { UserContext } from "../context/UserProvider";
import { Table } from "react-bootstrap";
import moment from "moment";

const Transaction = () => {
  const { logOut } = useContext(UserContext)
  const [getCallTransaction, setGetCallTransaction] = useState();
  const [totalEarningCall, setTotalEarningCall] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();
  const [totalAmountPenging, setTotalAmountPenging] = useState();
  const [totalAmountPaidUSD ,setTotalAmountPaidUSD]  = useState()
  const [totalAmountPengingUSD , setTotalAmountPengingUSD]  = useState()
  const [totalEarningCallUSD , setTotalEarningCallUSD]  = useState()

  const [astroTotalEarning, setAstroTotalEarning] = useState();
  const fetchData = async () => {
    const res = await ApiServices.get(`astrologer/getAstrologerOrders/completed`,
      {
        "content-type": "application/json",
        "Authorization": getUserToken(),
      }
    )

    if (res?.status == 200 && res?.data?.status) {

      setAstroTotalEarning(res.data.totalAstrologerEarning);
      setTotalAmountPaid(res.data.totalAmountPaid);
      setTotalAmountPenging(res.data.totalAmountPenging);
      setTotalEarningCall(res.data.totalEarningCall);

      setTotalAmountPaidUSD(res.data.totalAmountPaidUSD)
      setTotalAmountPengingUSD(res.data.totalAmountPengingUSD)
      setTotalEarningCallUSD(res.data.totalEarningCallUSD)
      
      const astrologerTransaction = res?.data?.data;
      const filteredCallData = astrologerTransaction.filter(order => order.orderType === "directCall");
      setGetCallTransaction(filteredCallData);
    }else{
      if(res.response.data.status==4){
        logOut()
      }
    }
  }
  const roundedNum = (num)=> parseFloat(num?.toFixed(2));

  useEffect(() => {
    fetchData()
  }, [])


  const statusMap = {
    1: { text: 'Pending', testClass: 'status_pending' },
    2: { text: 'Payment Done', testClass: 'status_success' },
    3: { text: 'Initiate', testClass: 'status_pending' },
    4: { text: 'Completed', testClass: 'status_success' },
    5: { text: 'Failed', testClass: 'status_failed' },
    6: { text: 'Reject', testClass: 'status_failed' },
    7: { text: 'Cancelled', testClass: 'status_failed' },
  };
  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper">
        <div className="astrologer_heading mb-lg-5 mb-md-5 mb-3">

          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <h1>Your Earnings</h1>
              <div className="wallet_earning item-set d-flex align-items-center" style={{columnGap:"50px"}}>

                <div className="item-set-bt"> 
                <h4>Total Call Earning: <span className="ml-3 d-block"> ₹ {roundedNum(totalEarningCall)} </span></h4>
                <h4>Total Paid: <span className="ml-3 d-block"> ₹ {roundedNum(totalAmountPaid)} </span></h4>
                <h4>Total Pending: <span className="ml-3 d-block"> ₹ {roundedNum(totalAmountPenging)} </span></h4>
                </div>
                <div className="item-set-bt"> 
                <h4>Total Call Earning: <span className="ml-3 d-block"> $ {roundedNum(totalEarningCallUSD)} </span></h4>
                <h4>Total Paid: <span className="ml-3 d-block"> $ {roundedNum(totalAmountPaidUSD)} </span></h4>
                <h4>Total Pending: <span className="ml-3 d-block"> $ {roundedNum(totalAmountPengingUSD)} </span></h4>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="support_chat_form">
          <h4>Call Details</h4>
          <div className="support_Chat_inner">
            <div className="support_scroll">
              <div className="row">
                <div>
                  {getCallTransaction && getCallTransaction?.length > 0
                    ?
                    <div>
                      <div className='table-responsive orderTable'>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Customer Name</th>
                              <th>Date</th>
                              <th>Call Duration</th>
                              <th>Status</th>
                              <th>Order Amount</th>
                              <th>Your Earning</th>
                              <th>Payment Status</th>
                            </tr>
                          </thead>
                          <tbody>

                            
                            {getCallTransaction.map((order,i) =>
                            

                              <tr key={order?._id}>
                                <td >{i+1}</td>
                                <td className="text-capitalize ">{order?.userId?.name}</td>
                                <td>{moment(order?.createdAt).format('MMMM Do YYYY')}</td>
                                <td>{order?.duration} min</td>
                                <td className="text-capitalize" ><span className={statusMap[order?.orderStatus]?.testClass}>{statusMap[order?.orderStatus]?.text}</span></td>
                                <td>{order?.currencySymbol}{order?.orderAmount}</td>
                                <td>{order?.currencySymbol}{order?.astrologerEarning ?? 0}</td>
                                <td className="text-capitalize">{order?.payStatus}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    : <div className="call_history_box my-5">
                      <p>No Calling Transaction data available</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default Transaction;