import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom";
import Home from "../../pages/Home";
import Profile from "../../pages/Profile";
import Account from "../../pages/Account";
import Calls from "../../pages/callingorder";
import Chats from "../../pages/chat";
import Login from "../../pages/Login";
import Signup from "../../pages/Signup";
import Transaction from "../../pages/transaction";
import ChatTransaction from "../../pages/chatTransaction";
import UserDetails from "../../pages/userDetails";
import PostDetails from "../../pages/Feeds";
import {useContext} from "react";
import {UserContext} from "../../context/UserProvider";
import {SocketContext} from "../../context/socketProvider";
import PaidQuestions from "../../pages/PaidQuestions";
import QuestDetails from "../../pages/QuestFeeds"

const ChatAuth = ({children}) => {
  const {chatInvocation} = useContext(SocketContext);
  if (!chatInvocation) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

function AppRouter() {
  const {isLoggedIn} = useContext(UserContext);
  return (
    <Router>
      <Routes>
        <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" replace={true} />} />
        <Route path="/calls" element={isLoggedIn ? <Calls /> : <Navigate to="/login" replace={true} />} />
        <Route path="/chats" element={<ChatAuth />}>
          <Route index element={isLoggedIn ? <Chats /> : <Navigate to="/login" replace={true} />} />
        </Route>
        <Route path="/account" element={isLoggedIn ? <Account /> : <Navigate to="/login" replace={true} />} />
        <Route path="/transaction" element={isLoggedIn ? <Transaction /> : <Navigate to="/login" replace={true} />} />
        <Route path="/chattransaction" element={isLoggedIn ? <ChatTransaction /> : <Navigate to="/login" replace={true} />} />
        <Route path="/profile" element={isLoggedIn ? <Profile /> : <Navigate to="/login" replace={true} />} />
        <Route path="/paidquestions" element={isLoggedIn ? <PaidQuestions /> : <Navigate to="/login" replace={true} />} />
        <Route path="/user/:orderid" element={isLoggedIn ? <UserDetails /> : <Navigate to="/login" replace={true} />} />
        <Route path="/feed/:feedid" element={isLoggedIn ? <PostDetails /> : <Navigate to="/login" replace={true} />} />
        <Route path="/questfeed/:feedid" element={isLoggedIn ? <QuestDetails /> : <Navigate to="/login" replace={true} />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/" replace={true} /> : <Login />} />
        <Route path="/signup" element={isLoggedIn ? <Navigate to="/" replace={true} /> : <Signup />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
