import React from "react";
import { useState, useContext } from "react";
import CommonForm from "../components/Form";
import ApiServices from "../utils/ApiServices";
import { UserContext } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [errorResponse, setErrorResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState("SignUP")
  const [email, setEmail] = useState()
  const { login } = useContext(UserContext)
  const navigate = useNavigate();

  const SignupForm = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
      name: data.name.trim(),
      email: data.email.toLowerCase().trim()
    }
    setEmail(payload.email);
    setErrorResponse(null);
    const res = await ApiServices.post("astrologer/signUp", payload)

    if (res.status == 200 && res.data.status == true) {
      setShowForm("otp")
      setErrorResponse({ status: res.data.status, message: res.data.message });
    } else {
      setErrorResponse({ status: res.data.status, message: res.data.message });
      console.log("SignUpError:", res);
    }
    setIsLoading(false);
  }

  const OTPForm = async (formData) => {
    let payload = {
      email: email,
      otp: formData.otp
    }
    const response = await ApiServices.post("astrologer/otpVerify", payload)
    if (response.status == 200 && response.data.status == true) {
      const userData = response.data.data[0]
      login(userData)
      setErrorResponse({ status: response.data.status, message: response.data.message });
    } else {
      setErrorResponse({ status: response.data.status, message: response.data.message });
      console.log("OTP Error:", response.data.message);
    }
  }

  return (
    <>
      <div className="as_main_wrapper">

        <section className="as_login_area as_signup_area">
          <img src="./images/login-shape.png" className="login-shape" />
          <div className="container">
            <div className="login_inner">
              <div className="login_logo">
                <img src="./images/logo_withtagline.png" />
              </div>
              {showForm === "SignUP" ? <CommonForm
                formType="Signup_Form"
                submitForm={SignupForm}
                formData={{ errorResponse, isLoading }}
              />
                : <CommonForm
                  formType="Otp_form"
                  submitForm={OTPForm}
                  formData={{ errorResponse, email, isLoading}}
                />
              }
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Signup;