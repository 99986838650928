import React, {createContext, useState} from "react";
import {isAuthenticated} from "../utils/helper";
import {setCookie, deleteCookie} from "../utils/helper";
const UserContext = createContext();

const UserProvider = ({children}) => {
  const checkCookie = isAuthenticated();
  const [isLoggedIn, setIsLoggedIn] = useState(checkCookie);
  const [endCall, setEndCall] = useState(false);

  const endCallByUserEnd = (val) => {
    setEndCall(val);
  };

  const logOut = () => {
    deleteCookie("astrologer_data");
    setIsLoggedIn(false);
  };

  const login = userData => {
    setCookie("astrologer_data", userData);
    setIsLoggedIn(true);
  };

  return <UserContext.Provider value={{isLoggedIn, logOut, login, endCallByUserEnd,  endCall}}>{children}</UserContext.Provider>;
};

export {UserProvider, UserContext};
// export default UserProvider;
