import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const AstSelect = (props) => {
  const {
    closeOnSelect = true,
    hideOptionOnSelect = false,
    selected = [],
    multi = false,
    selectOptions = null,
    changeFn = null,
    createFn = null,
    cClass = "",
    cClassPrefix = "",
    selectPlaceholder = "Select From Here",
    selectType = "select",
    creatablePosition = "first",
  } = props;

  if (selectType === "creatable") {
    return (
      <CreatableSelect
        closeMenuOnSelect={closeOnSelect}
        hideSelectedOptions={hideOptionOnSelect}
        defaultValue={selected}
        isMulti={multi ? true : false}
        options={selectOptions}
        onChange={changeFn}
        onCreateOption={createFn}
        createOptionPosition={creatablePosition}
        className={cClass}
        classNamePrefix={cClassPrefix}
        placeholder={selectPlaceholder}
      />
    );
  } else {
    return (
      <Select
        closeMenuOnSelect={closeOnSelect}
        hideSelectedOptions={hideOptionOnSelect}
        defaultValue={selected}
        isMulti={multi ? true : false}
        options={selectOptions}
        onChange={changeFn}
        className={cClass}
        classNamePrefix={cClassPrefix}
        placeholder={selectPlaceholder}
      />
    );
  }
};

export default AstSelect;
