import { useState } from "react";
import Toast from 'react-bootstrap/Toast';

const Errortoast = (props) => {
  const msg = props.message;
  const [showA, setShowA] = useState(true);

  const toggleShowA = () => 
  {
    setShowA(false);
  }
  
  return (
    <>
      <Toast show={showA} onClose={toggleShowA}  className="error_toast">
        <Toast.Body>{msg}</Toast.Body>
        <Toast.Header/>
      </Toast>
    </>
  )

}
export default Errortoast;