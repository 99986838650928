import React, { useEffect, useState } from "react";
import useAgora from "./Hook";
import Controls from "./Controls";
import WebDashboard from "./Dashboard";

const VideoCall = (props) => {
  const { channelName, appID, token, userProfile, handelLeaveCall } = props;

  const [useClient, useMicrophoneAudioTrack] = useAgora();
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [audio, setAudio] = useState('');
  const client = useClient();
  const { ready, track } = useMicrophoneAudioTrack();

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio") {
          user.audioTrack?.stop();
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(appID, name, token, null);

      if (track) await client.publish(track);
      {
        setStart(true);
      }
    };

    if (ready && track) {
      init(channelName);
      setAudio(track)
    }
  }, [channelName, ready, track]);
  return (
    <div className="App">
      {start && track && <WebDashboard
        users={users}
        userProfile={userProfile} 
        client={client}/>}
      {ready && track && <Controls
        track={audio}
        setStart={setStart}
        handelLeaveCall={handelLeaveCall} />}
    </div>
  );
};

export default VideoCall;
