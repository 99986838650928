import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import { getCookeiDetail } from "../utils/helper";
import ApiServices from "../utils/ApiServices";
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { PencilSquare, Heart, HeartFill,ArrowLeft } from 'react-bootstrap-icons';
import { getUserToken } from "../utils/helper";

const Feed = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAvailable, setIsAvailable] = useState(false);
  const [postData, setPostData] = useState();
  const [comment, setComment] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [replyAll, setReplyAll] = useState([]);
  const [mode, setMode] = useState('');
  const data = getCookeiDetail("astrologer_data")
  const[loading,setLoading]=useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!params) navigate(`/`, { replace: true });
      let payload = {
        "postId": params.feedid,
        "commentPage": 1,
        "commentLimit": 10
      }
      const res = await ApiServices.post(`astrologer/post/detail`, payload, {
        Authorization: getUserToken()
      });
      if (res.status == 200 && res.data.status == true) {
        // console.log("resresresresresresresres",res)
        setPostData(res.data.data)
        const Data = res.data.data[0];
        
        if (Data?.availability == 2) {
          setIsAvailable(false)
        } else {
          setIsAvailable(true)
        }
      } else {
        console.log("Error:", res);
      }
    }
    fetchData()
  }, [refresh])

  const handleLikeCount = async (row) => {
    let payload = {
      "postId": row?._id,
      "likeStatus": !row.likeStatus
    }
    let res = await ApiServices.post("astrologer/post/like_dislike", payload, {
      Authorization: getUserToken()
    })
    if (res.status == 200)
      setRefresh(!refresh)
    else {
      console.log('res', res)
    }
  }

  // const availabilityHandle = async () => {
  //   let payload = {};
  //   if (isAvailable) {
  //     payload.availability = 2
  //   }
  //   else {
  //     payload.availability = 1
  //   }
  //   const resp = await ApiServices.put("astrologer/updateAvailability", payload, {
  //      Authorization: getUserToken()
  //    });
  //   if (resp.status == 200 && resp.data.status == true) {
  //     setIsAvailable(!isAvailable)
  //   }
  //   else {
  //     console.log("ERROR", resp);
  //   }
  // }

  const handleWriteComment = async (reply) => {
    if (comment) {
      if (reply) {
        let payload = {
          "postId": reply.postId,
          "commentId": reply._id,
          "replyDescription": comment
        }
        const res = await ApiServices.post(`astrologer/post/reply_on_comment`, payload, {
          Authorization: getUserToken()
        });
        if (res.status == 200 && res.data.status == true) {
          addToast(res?.data?.message, { appearance: 'success' });
          setLoading(false)
          setComment('');
          setMode('');
          setRefresh(!refresh)
        } else {
          console.log("Error:", res);
        }
      } else {
        let payload = {
          "postId": params.feedid,
          "description": comment
        }
        const res = await ApiServices.post(`astrologer/post/add_comment`, payload, {
          Authorization: getUserToken()
        });
        if (res.status == 200 && res.data.status == true) {
          addToast(res?.data?.message, { appearance: 'success' });
          setComment(''); setRefresh(!refresh)
          setLoading(false)
        } else {
          console.log("Error:", res);
        }
      }
    } else {
      addToast(`Please enter ${reply ? 'reply' : 'comments'}!`, { appearance: 'error' });
    }
  }

  const handleReply = (repData) => {
    document.getElementById('comments').focus();
    setMode(repData);
  }

  const showAllReply = async (data) => {
    let payload = {
      "postId": data?.postId,
      "commentId": data?._id
    }
    const res = await ApiServices.post(`astrologer/post/reply_list`, payload, {
      Authorization: getUserToken()
    });
    if (res.status == 200 && res.data.status == true) {
      setReplyAll(res?.data?.list[0]);
      setExpanded(true)
    } else {
      console.log("Error:", res);
    }
  }

  return (
    <>
      <Header />
      <section className="as_zodiac_single_wrapper astrologer_feed_area">
        <div className="astrologer_heading">
          <div className="container d-flex justify-content-between">
            <h4>
            <ArrowLeft
              className="cursor-pointer"
              style={{marginRight: "15px"}}
              size={25}
              onClick={() => navigate(-1)}/>
              Welcome!<span className="fw-bold limitcar text-capitalize">{data?.name}</span> </h4>
            {/* <div className="d-flex align-items-center flex-column flex-lg-row flex-md-row">
              <Switch
                isOn={isAvailable}
                onColor="#347A16"
                handleToggle={() =>
                  availabilityHandle()}
              />
              {isAvailable ? <p style={{ color: "green", margin: "5px 10px", lineHeight: '1rem', visibility: isAvailable ? "visible" : "hidden" }}> You're Live</p> : <p style={{ color: "red", margin: "5px 10px", lineHeight: '1rem', visibility: !isAvailable ? "visible" : "hidden" }}> You're Offline</p>}
            </div> */}
          </div>
        </div>
        <div className="astrologer_feed_inner mt-3 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-9">
                <div className="astro_feed_sec">
                  <div className="card _feed_box mt-3">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="user_info d-flex align-items-center">
                        <img src={postData?.astrologerId?.profileImage || postData?.userId?.profileImage} className="img-fluid user_img" />
                        <div className="ms-2">
                          <h4>{postData?.astrologerId?.name || postData?.userId?.name}</h4>
                          <p>{moment(postData?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
                        </div>
                      </div>
                      <a href="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a>
                    </div>
                    <div className="card-body">
                      <p>{postData?.description}</p>
                      <img src={postData?.mediaFile} className="img-fluid" />
                    </div>
                    <div className="card-footer">
                      <ul className="nav">
                        <li className="cursor-pointer">
                          <span onClick={() => handleLikeCount(postData)}>
                            {postData?.likeStatus === false ? (
                              <Heart />
                            ) : (
                                <HeartFill style={{ color: '#B21414' }} />
                              )} </span>{' '}
                          Like {postData?.likeCount}
                          {/* <a href="#"><i className="fa fa-heart me-2" aria-hidden="true"></i> Like {postData?.likeCount}</a> */}
                        </li>
                        <li className="cursor-pointer">
                          <a href="#comments"><i className="fa fa-comment-o me-2" aria-hidden="true"></i>Comments {postData?.commentCount}</a>
                        </li>
                        {/* <li className="cursor-pointer">
                          <a href="#"><i className="fa fa-paper-plane me-2" aria-hidden="true"></i>Share {postData?.shareCount}</a>
                        </li> */}
                      </ul>
                      <div className="feed_comments_sec">
                        <a href="" className="comments">View Comments {postData?.commentCount}</a>
                        <ul>
                          {postData?.comments && postData?.comments.map(comment => (
                            <li key={comment?._id}>
                              <div className="card-header d-flex align-items-center justify-content-between">
                                <div className="user_info d-flex align-items-center">
                                  <img src={comment?.astrologerId?.profileImage || comment?.userId?.profileImage} className="img-fluid user_img" />
                                  <div className="ms-2">
                                    <h4>{comment?.astrologerId?.name || comment?.userId?.name}</h4>
                                    <p>{moment(comment?.createdAt).format('MMM Do YYYY, h:mm A')}</p>
                                  </div>
                                </div>
                                <a href="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a>
                              </div>
                              <p>{(comment?.description)}</p>
                              <a className="reply cursor-pointer" onClick={() => handleReply(comment)}>Reply {comment?.totalReply > 0 ? comment?.totalReply : null}</a>
                              {comment?.totalReply > 1 ? <button className="as_btn log_btn" onClick={() => { expanded && replyAll?._id === comment?._id ? setExpanded(false) : showAllReply(comment) }}> {expanded && replyAll?._id === comment?._id ? 'Show Less' : 'Show More'}</button> : null}
                              {comment?.totalReply > 0 &&
                                <span>
                                  {comment?.reply.length > 0 && (expanded && replyAll?._id === comment?._id ? replyAll?.reply : comment?.reply).map(rep => (
                                    <ul className="fcs_reply_sec ms-2" key={rep?._id}>
                                      <li className="mb-3 fcs_reply_sec">
                                        <div className="user_info d-flex align-items-center justify-content-between">
                                          <div className="ms-2">
                                            <h4>{rep?.replyAstrologerId?.name || rep?.replyUserId?.name} </h4>
                                            <p>{moment(rep?.replyDate).format('MMM Do YYYY, h:mm A')}</p>
                                          </div>
                                          <h5>Replied</h5>
                                        </div>

                                        <div className="des d-flex align-items-start justify-content-between">
                                          <p>{rep?.replyDescription}</p>
                                          <a href="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a>
                                        </div>
                                      </li>
                                    </ul>
                                  ))}
                                </span>
                              }
                            </li>
                          ))}
                          <li>
                            <div className="reply_input_sec">
                              <input type="text" placeholder={`Write ${mode ? 'Reply...' : 'Comments...'}`} value={comment} onChange={e => setComment(e.target.value)} id="comments" />
                             {loading?<button className="btn" >Processing...</button>:<button className="btn" onClick={() => {handleWriteComment(mode); setLoading(true)}}>POST</button>} 
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="feeds_requset_sec mt-3">
                  <div className="wall_box d-flex align-items-center">
                    <PencilSquare className='myWallPencil' />
                    <h5>My Wall</h5>
                  </div>
                  {/* <div className="_chat_request mt-4">
                    <h4>CHAT REQUEST -</h4>
                    <ul>
                      <li className="d-flex align-items-start">
                        <img src="/images/feed/user.png" className="img-fluid user_img me-2" />
                        <div>
                          <h4>Pankaj Soni</h4>
                          <p>Nov 09, 2022 10.36pm</p>
                          <div className="d-flex align-items-center">
                            <a href="#" className="accept me-1">ACCEPT</a>
                            <a href="#" className="reject">REJECT</a>
                          </div>
                        </div>
                      </li>
                      <li className="d-flex align-items-start">
                        <img src="/images/feed/user.png" className="img-fluid user_img me-2" />
                        <div>
                          <h4>Neha Shrivastav</h4>
                          <p>Nov 09, 2022 10.36pm</p>
                          <div className="d-flex align-items-center">
                            <a href="#" className="accept me-1">ACCEPT</a>
                            <a href="#" className="reject">REJECT</a>
                          </div>
                        </div>
                      </li>
                      <li className="d-flex align-items-start">
                        <img src="/images/feed/user.png" className="img-fluid user_img me-2" />
                        <div>
                          <h4>Anshul Shrivastav</h4>
                          <p>Nov 09, 2022 10.36pm</p>
                          <div className="d-flex align-items-center">
                            <a href="#" className="accept me-1">ACCEPT</a>
                            <a href="#" className="reject">REJECT</a>
                          </div>
                        </div>
                      </li>
                      <li className="d-flex align-items-start">
                        <img src="/images/feed/user.png" className="img-fluid user_img me-2" />
                        <div>
                          <h4>Mohit Tiwari</h4>
                          <p>Nov 09, 2022 10.36pm</p>
                          <div className="d-flex align-items-center">
                            <a href="#" className="accept me-1">ACCEPT</a>
                            <a href="#" className="reject">REJECT</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="_call_request mt-4">
                    <h4>CALL REQUEST -</h4>
                    <div className="_call_req_inn">
                      <img src="/images/feed/call_history.png" className="img-fluid" />
                      <h4>Rakesh Soni</h4>
                      <p>Nov 09, 2022 10.36pm</p>
                      <a href="#" className="as_btn mt-4">CALL HISTORY</a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Feed;